import type { PaymentStatus } from "@/types";

export default function getPaymentStatus(filters: {
  paid: boolean;
  not_paid: boolean;
}): PaymentStatus | undefined {
  const { paid, not_paid: notPaid } = filters;

  if (paid && notPaid) return undefined;

  if (paid) return "paid";

  if (notPaid) return "not_paid";

  return undefined;
}
