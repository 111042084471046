import { colors } from "@/config/theme";
import { ConfigProvider } from "antd";
import type { ThemeConfig } from "antd";

interface Props {
  children: React.ReactElement;
}

const theme: ThemeConfig = {
  token: {
    colorPrimary: colors.primary,
    colorLink: colors.primary,
  },
};

export default function ThemeProvider(props: Props): React.ReactElement {
  const { children } = props;

  return <ConfigProvider theme={theme}>{children}</ConfigProvider>;
}
