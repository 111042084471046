import type { IBranch } from "@/types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface BranchState {
  branch: number | null;
  setBranch: (branch: number | null) => void;
  branchConfig: IBranch[] | null;
  setBranchConfig: (branch: IBranch[] | null) => void;
}

const useBranchConfig = create<BranchState>()(
  persist(
    (set) => ({
      branchConfig: null,
      setBranchConfig: (branch) => {
        set({ branchConfig: branch });
      },
      branch: null,
      setBranch: (branch) => {
        set({ branch });
      },
    }),
    {
      name: "branch-storage",
      getStorage: () => localStorage,
    },
  ),
);

export default useBranchConfig;
