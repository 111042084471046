import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang); // Tanlangan tilni saqlash
  };

  return (
    <div className='flex items-center gap-2 text-18 uppercase'>
      <span
        className={`cursor-pointer ${i18n.language === 'ru' ? 'text-[#2f3138]' : 'text-[#7d848b]'}`}
        onClick={() => changeLanguage('ru')}
      >
        {i18n.language === "ru" ? "РУ":"RU"}
      </span>
      {i18n.language === 'ru' ? <span className='text-[#2f3138]'>|</span> : null}

      <span
        className={`cursor-pointer ${i18n.language === 'en' ? ' text-[#2f3138]' : 'text-[#7d848b]'}`}
        onClick={() => changeLanguage('en')}
      >
        EN
      </span>
      {i18n.language === 'en' ? <span className='text-[#2f3138]'>|</span> : null}
    </div>
  );
};

export default LanguageSwitcher;
