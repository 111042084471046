import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const bluedishSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
  >
    <path d="M1.16602 12.166H17.8327" stroke="#5566FF" stroke-linecap="round" />
    <path
      d="M9.5 3.83333C9.5 3.83333 10.75 2.97199 10.75 1.90946C10.75 0.0301797 8.25 0.0301797 8.25 1.90946C8.25 2.97199 9.5 3.83333 9.5 3.83333Z"
      stroke="#5566FF"
      stroke-linejoin="round"
    />
    <path
      d="M2 12.166L2.51772 14.2369C2.70321 14.9789 3.36985 15.4993 4.13463 15.4993H14.8654C15.6302 15.4993 16.2968 14.9789 16.4823 14.2369L17 12.166"
      stroke="#5566FF"
      stroke-linecap="round"
    />
    <path
      d="M16.5827 10.084C16.1674 6.56468 13.1545 3.83398 9.49935 3.83398C5.84423 3.83398 2.8313 6.56468 2.41602 10.084"
      stroke="#5566FF"
      stroke-linecap="round"
    />
  </svg>
);

export default function DishBlueIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={bluedishSvg} {...props} />;
}
