import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const circleArrowDownSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
  >
    <circle
      cx="12.4004"
      cy="12.5"
      r="10"
      fill="currentColor"
      fillOpacity="0.15"
    />
    <path
      d="M12.4004 8.5V16.5M12.4004 16.5L14.9004 14M12.4004 16.5L9.90039 14"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function CircleArrowDownIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={circleArrowDownSvg} {...props} />;
}
