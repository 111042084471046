import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Drawer } from "antd";
import { useMutation } from "@tanstack/react-query";
import { lightFormat } from "date-fns";
import { uid } from "radash";
import queryClient from "@/utils/query-client";
import CancelIcon from "@/components/cancel-icon";
import OrderItemList from "./order-item-list";
import OrderActions from "./order-actions";
import { deleteOrderItem, updateOrderItem } from "../api";
import type { Order } from "../types";

interface Props {
  order: Order | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessNotify: (message: string) => void;
  setIsChequeOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OrderDetails(props: Props): React.ReactElement {
  const { order, isOpen, setIsOpen, setIsChequeOpen, onSuccessNotify } = props;

  const { t } = useTranslation();

  const navigate = useNavigate();

  const itemUpdateMut = useMutation({
    mutationFn: async (data: {
      orderId: number;
      payload: { id: number; product: number; qty: number };
    }) => {
      const { orderId, payload } = data;
      await updateOrderItem(orderId, payload);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["orders"]);
    },
  });

  const itemDelMut = useMutation({
    mutationFn: async (data: { orderId: number; payload: { id: number } }) => {
      const { orderId, payload } = data;
      await deleteOrderItem(orderId, payload);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["orders"]);
      void queryClient.invalidateQueries(["order", `${order?.id}`]);
    },
  });

  const onOrderItemInc = (opts: {
    id: number;
    qty: number;
    product: number;
  }): void => {
    itemUpdateMut.mutate({ orderId: order.id, payload: opts });
  };

  const onOrderItemDec = (opts: {
    id: number;
    qty: number;
    product: number;
  }): void => {
    itemUpdateMut.mutate({ orderId: order.id, payload: opts });
  };

  const onDeleteItem = (opts: { id: number }): void => {
    itemDelMut.mutate({ orderId: order.id, payload: opts });
  };

  const onSeeCheck = (): void => {
    setIsChequeOpen(true);
  };

  return (
    <Drawer
      title={
        <>
          <div className="flex justify-between items-start">
            <div className="flex flex-col gap-2">
              <span className="font-normal text-gray-500 text-sm leading-4">
                {t("issued-at")}{" "}
                {typeof order?.created_at !== "undefined"
                  ? lightFormat(new Date(order.created_at), "yyyy.MM.dd HH:mm")
                  : null}{" "}
                | {t(order?.status ?? "")}
              </span>
              <h2 className="font-semibold text-2xl leading-7">
                {t("order")} №{order?.id}
              </h2>
            </div>

            <button
              type="button"
              className="bg-transparent active:text-gray-700"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CancelIcon />
            </button>
          </div>
          <Divider className="mt-4 mb-0" />
        </>
      }
      placement="right"
      width={512}
      // getContainer={() => document.querySelector("main > div")!}
      // rootStyle={{ position: "absolute" }}
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      closeIcon={null}
    >
      {typeof order !== "undefined" && (
        <OrderItemList
          order={order}
          onOrderItemInc={onOrderItemInc}
          onOrderItemDec={onOrderItemDec}
          onDeleteItem={onDeleteItem}
        />
      )}

      {order?.payment_status === "not_paid" ? (
       <div
       className={`flex items-center justify-center ${
         order?.payment_type?.id === 5 ? "-mt-8" : ""
       }`}
     >
          <Button
            onClick={() => {
              navigate(`/orders/edit-order/${order.id}?cart_id=${uid(6)}`);
            }}
          >
            {t("add-more-dish")}
          </Button>
        </div>
      ) : null}

      <OrderActions
        order={order}
        onSeeCheck={onSeeCheck}
        setIsOpen={setIsOpen}
        onSuccessNotify={onSuccessNotify}
      />
    </Drawer>
  );
}
