import request from "@/utils/axios";
import type { BaseParams, ListResponse } from "@/types";
import type { AxiosResponse } from "axios";
import type {
  Category,
  ChequeData,
  CreateOrderData,
  EditOrderData,
  Order,
  OrdersParams,
  PaymentType,
  Position,
  UpdateOrderData,
} from "../types";

export async function getOrders(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/",
    method: "get",
    params,
  });

  return res;
}

export async function getOrder(id: number): Promise<Order> {
  const res: Order = await request({
    url: `/order/${id}/`,
    method: "get",
  });

  return res;
}

export async function createOrder(
  data: CreateOrderData,
): Promise<{ id: number }> {
  const res: { id: number } = await request({
    url: "/order/",
    method: "post",
    data,
  });

  return res;
}

export async function updateOrder(
  id: number,
  data: UpdateOrderData,
): Promise<void> {
  await request({
    url: `/order/${id}/`,
    method: "put",
    data,
  });
}

export async function editOrder(
  id: number,
  data: EditOrderData,
): Promise<void> {
  await request({
    url: `/order/${id}/update-order/`,
    method: "post",
    data,
  });
}

export async function archiveOrder(id: number): Promise<void> {
  await request({
    url: `/order/${id}/set_order_status_archive/`,
    method: "post",
  });
}

export async function getPaymentTypes(
  params?: BaseParams,
): Promise<ListResponse<PaymentType[]>> {
  const res: ListResponse<PaymentType[]> = await request({
    url: "/payment-types-admin/",
    method: "get",
    params,
  });

  return res;
}

export async function getClients(
  params?: BaseParams,
): Promise<ListResponse<PaymentType[]>> {
  const res: ListResponse<PaymentType[]> = await request({
    url: "/clients/",
    method: "get",
    params,
  });

  return res;
}

export async function getCategories(
  params?: BaseParams,
): Promise<ListResponse<Category[]>> {
  const res: ListResponse<Category[]> = await request({
    url: "/menu/category/",
    method: "get",
    params,
  });

  return res;
}

export async function addOrderItem(
  orderId: number,
  params: { product: number; qty: number },
): Promise<void> {
  await request({
    url: `/order/${orderId}/add-order-item/`,
    method: "post",
    params,
  });
}

export async function updateOrderItem(
  orderId: number,
  data: { id: number; product: number; qty: number },
): Promise<void> {
  await request({
    url: `/order/${orderId}/update-order-item/`,
    method: "put",
    data,
  });
}

export async function deleteOrderItem(
  orderId: number,
  data: { id: number },
): Promise<void> {
  await request({
    url: `/order/${orderId}/delete-order-item/`,
    method: "post",
    data,
  });
}

export async function getChequeData(
  id: number,
  params?: BaseParams,
): Promise<ChequeData> {
  const res: ChequeData = await request({
    url: `/order/${id}/check/`,
    method: "post",
    params,
  });

  return res;
}

export async function updatePositions(data: {
  position: Position[];
}): Promise<void> {
  await request({
    url: "/update-positions/",
    method: "put",
    data,
  });
}

export async function createCheck(
  data: CreateOrderData,
): Promise<{ id: number }> {
  const res: AxiosResponse<{ id: number }> = await request({
    url: "/order/create/check",
    method: "post",
    data,
  });
  return res.data;
}

export async function closeOrders(
  data: CreateOrderData,
): Promise<{ id: number }> {
  const res: { id: number } = await request({
    url: "/order/close/orders/",
    method: "post",
    data,
  });

  return res;
}
