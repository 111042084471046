import { useEffect } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Divider, Drawer } from "antd";
import PrinterIcon from "@/components/printer-icon";
import brandLogo from "/brand-logo.svg";
import type { Order } from "../features/all-orders";
import FetchAndDisplayHTML from "./check-example";

interface Props {
  order: Order | undefined;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function Cheque(props: Props): React.ReactElement {
  const { order, isOpen, setIsOpen } = props;

  const { t } = useTranslation();

  const onPrint = (): void => {
    const iframe = document.getElementById("check") as HTMLIFrameElement;

    if (iframe !== null) {
      iframe?.contentWindow?.postMessage({ action: "download" });
    }
  };

  useEffect(() => {
    const iframe = document.getElementById("check") as HTMLIFrameElement;

    if (iframe !== null && typeof order !== "undefined") {
      setTimeout(() => {
        iframe?.contentWindow?.postMessage({ action: "update", order, isOpen });
      }, 500);
    }
  }, [order, isOpen]);

  return (
    <Drawer
      title={
        <>
          <div className="flex items-start justify-between">
            <img src={brandLogo} alt="brand-logo" />

            <CloseOutlined
              style={{ fontSize: "18px" }}
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
          <Divider className="my-4" />
        </>
      }
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
      rootClassName="custom-drawer"
      placement="right"
      width={512}
      closeIcon={null}
      // getContainer={() => document.querySelector("main > div")!}
      // rootStyle={{ position: "absolute" }}
    >
      {/* {order?.payment_type?.id === 5 ? (
        <iframe
          id="checkPalm"
          title="CheckPalm"
          src="/check-palm"
          className="w-full h-full"
          style={{
            height: "calc(100vh - 93px - 112px - 16px)",
          }}
        />
      ) : ( */}
        <iframe
          id="check"
          title="Check"
          src="/check"
          className="w-full h-full"
          style={{
            height: "calc(100vh - 93px - 112px - 16px)",
          }}
        />
      {/* )} */}

      <div className="bg-[#f5f5f5] rounded-t-2xl absolute bottom-0 w-full left-0 p-6">
        <Button
          type="primary"
          className="w-full flex items-center justify-center my-4"
          onClick={onPrint}
        >
          <PrinterIcon />
          {t("print")}
        </Button>
      </div>
    </Drawer>
  );
}
