import InternalServerError from "@/views/internal-server-error";
import Expenses from "./views";
import canSee from "@/helpers/can-see";

const expensesRoutes = canSee({
  id: "expenses",
  title: "expenses",
  path: "expenses",
  element: <Expenses />,
  errorElement: <InternalServerError />,
  children: [],
});

export default expensesRoutes;
