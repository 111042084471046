import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const preferencesSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <g id="elements">
      <path
        id="Vector 768"
        d="M11.5 3L1.5 3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 769"
        d="M9.5 18L1.5 18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 770"
        d="M19.5 18L15.5 18"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 771"
        d="M19.5 10.5L9.5 10.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 772"
        d="M19.5 3L17.5 3"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 773"
        d="M3.5 10.5L1.5 10.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Rectangle 2142"
        d="M13 1C13.4659 1 13.6989 1 13.8827 1.07612C14.1277 1.17761 14.3224 1.37229 14.4239 1.61732C14.5 1.80109 14.5 2.03406 14.5 2.5L14.5 3.5C14.5 3.96594 14.5 4.19891 14.4239 4.38268C14.3224 4.62771 14.1277 4.82239 13.8827 4.92388C13.6989 5 13.4659 5 13 5C12.5341 5 12.3011 5 12.1173 4.92388C11.8723 4.82239 11.6776 4.62771 11.5761 4.38268C11.5 4.19891 11.5 3.96594 11.5 3.5L11.5 2.5C11.5 2.03406 11.5 1.80109 11.5761 1.61732C11.6776 1.37229 11.8723 1.17761 12.1173 1.07612C12.3011 1 12.5341 1 13 1Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Rectangle 2143"
        d="M11 16C11.4659 16 11.6989 16 11.8827 16.0761C12.1277 16.1776 12.3224 16.3723 12.4239 16.6173C12.5 16.8011 12.5 17.0341 12.5 17.5L12.5 18.5C12.5 18.9659 12.5 19.1989 12.4239 19.3827C12.3224 19.6277 12.1277 19.8224 11.8827 19.9239C11.6989 20 11.4659 20 11 20C10.5341 20 10.3011 20 10.1173 19.9239C9.87229 19.8224 9.67761 19.6277 9.57612 19.3827C9.5 19.1989 9.5 18.9659 9.5 18.5L9.5 17.5C9.5 17.0341 9.5 16.8011 9.57612 16.6173C9.67761 16.3723 9.87229 16.1776 10.1173 16.0761C10.3011 16 10.5341 16 11 16Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Rectangle 2144"
        d="M8 8.5C8.46594 8.5 8.69891 8.5 8.88268 8.57612C9.12771 8.67761 9.32239 8.87229 9.42388 9.11732C9.5 9.30109 9.5 9.53406 9.5 10L9.5 11C9.5 11.4659 9.5 11.6989 9.42388 11.8827C9.32239 12.1277 9.12771 12.3224 8.88268 12.4239C8.69891 12.5 8.46594 12.5 8 12.5C7.53406 12.5 7.30109 12.5 7.11732 12.4239C6.87229 12.3224 6.67761 12.1277 6.57612 11.8827C6.5 11.6989 6.5 11.4659 6.5 11L6.5 10C6.5 9.53406 6.5 9.30109 6.57612 9.11732C6.67761 8.87229 6.87229 8.67761 7.11732 8.57612C7.30109 8.5 7.53406 8.5 8 8.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default function PreferencesIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={preferencesSvg} {...props} />;
}
