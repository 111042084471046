import type { CustomRoute } from "@/types";

export default function canSee(route: CustomRoute): CustomRoute | null {
  const position = localStorage.getItem("position");
  if (position === "kassir") {
    return null;
  }

  return route;
}
