import canSee from "@/helpers/can-see";
import InternalServerError from "@/views/internal-server-error";
import EmployeeControl from "./views";

const menuControlRoutes = canSee({
  id: "employee-control",
  title: "employee-control",
  path: "employee-control",
  element: <EmployeeControl />,
  errorElement: <InternalServerError />,
  children: [],
});

export default menuControlRoutes;
