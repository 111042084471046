import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const historyDeptsSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4.206 7.17149L2.11422 7.04415C3.61362 3.08666 7.91852 0.832613 12.1157 1.9533C16.586 3.14694 19.2413 7.71691 18.0464 12.1606C16.8516 16.6044 12.259 19.239 7.78868 18.0454C4.46951 17.1591 2.15095 14.4115 1.66602 11.2364"
      stroke="#5566FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 6.66602V9.99935L11.6667 11.666"
      stroke="#5566FF"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default function HistoryDeptIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={historyDeptsSvg} {...props} />;
}
