import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const archiveSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="none"
  >
    <g id="elements">
      <path
        id="Rectangle 116"
        d="M1.41602 12.334C1.41602 10.3849 1.41602 9.41037 1.86307 8.69889C2.09619 8.32788 2.40991 8.01416 2.78092 7.78104C3.49241 7.33398 4.46694 7.33398 6.41602 7.33398H13.0827C15.0318 7.33398 16.0063 7.33398 16.7178 7.78104C17.0888 8.01416 17.4025 8.32788 17.6356 8.69889C18.0827 9.41037 18.0827 10.3849 18.0827 12.334C18.0827 14.2831 18.0827 15.2576 17.6356 15.9691C17.4025 16.3401 17.0888 16.6538 16.7178 16.8869C16.0063 17.334 15.0318 17.334 13.0827 17.334H6.41602C4.46694 17.334 3.49241 17.334 2.78092 16.8869C2.40991 16.6538 2.09619 16.3401 1.86307 15.9691C1.41602 15.2576 1.41602 14.2831 1.41602 12.334Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Rectangle 117"
        d="M16.4154 7.33333C16.4154 6.16656 16.4154 5.58317 16.1883 5.13752C15.9886 4.74552 15.6698 4.42681 15.2778 4.22707C14.8322 4 14.2488 4 13.082 4H6.41536C5.24859 4 4.6652 4 4.21955 4.22707C3.82755 4.42681 3.50884 4.74552 3.3091 5.13752C3.08203 5.58317 3.08203 6.16656 3.08203 7.33333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Rectangle 118"
        d="M14.75 4.00033C14.75 2.42898 14.75 1.6433 14.2618 1.15515C13.7737 0.666992 12.988 0.666992 11.4167 0.666992H8.08333C6.51198 0.666992 5.72631 0.666992 5.23816 1.15515C4.75 1.6433 4.75 2.42898 4.75 4.00033"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 4033"
        d="M12.25 10.667C12.25 11.5875 11.5038 12.3337 10.5833 12.3337H8.91667C7.99619 12.3337 7.25 11.5875 7.25 10.667"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default function ArchiveIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={archiveSvg} {...props} />;
}
