import canSee from "@/helpers/can-see";
import InternalServerError from "@/views/internal-server-error";
import Report from "./views";

const reportRoutes = canSee({
  id: "reports",
  title: "reports",
  path: "reports",
  element: <Report />,
  errorElement: <InternalServerError />,
  children: [],
});

export default reportRoutes;
