import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const noteDebtSvg: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.7328 5.84648L14.7102 6.90622M9.88017 9.01048L11.8689 9.54035M9.97909 14.9714L10.7746 15.1833C13.0245 15.7828 14.1495 16.0825 15.0358 15.5737C15.9221 15.0649 16.2235 13.9463 16.8264 11.709L17.679 8.54501C18.2819 6.30773 18.5833 5.18908 18.0716 4.30782C17.5599 3.42655 16.4349 3.12681 14.185 2.52733L13.3895 2.31538C11.1395 1.7159 10.0145 1.41616 9.12826 1.92496C8.24199 2.43377 7.94056 3.55241 7.33768 5.78969L6.48508 8.95369C5.8822 11.191 5.58076 12.3096 6.09245 13.1909C6.60413 14.0722 7.72912 14.3719 9.97909 14.9714Z"
      stroke="#2F3138"
      stroke-width="1.2"
      strokeLinecap="round"
    />
    <path
      d="M9.99935 17.4539L9.20573 17.6701C6.96104 18.2813 5.8387 18.5869 4.95451 18.0681C4.07032 17.5493 3.76959 16.4088 3.16813 14.1276L2.31753 10.9015C1.71607 8.62037 1.41534 7.47979 1.92582 6.58123C2.36741 5.80396 3.33268 5.83225 4.58268 5.83216"
      stroke="#2F3138"
      stroke-width="1.2"
      strokeLinecap="round"
    />
  </svg>
);

export default function NoteDeptIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={noteDebtSvg} {...props} />;
}
