import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import placeholderIcon from "/default_image.svg";

export default function DishImage(props: {
  src: string | null;
  width?: number;
  height?: number;
}): React.ReactElement {
  const { src, width, height } = props;

  return (
    <div
      className="flex items-center justify-center border rounded-lg overflow-hidden"
      style={{
        width: width ?? 48,
        height: height ?? 48,
      }}
    >
      <LazyLoadImage
        // placeholderSrc={placeholderIcon}
        src={src ?? placeholderIcon}
        effect="opacity"
        alt="dish"
        onError={(e) => {
          const imgElm = e.target as HTMLImageElement;
          imgElm.onerror = null;
          imgElm.src = placeholderIcon;
        }}
        className="w-full"
      />
    </div>
  );
}

DishImage.defaultProps = {
  width: 48,
  height: 48,
};
