import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const buildingSvg: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 22L2 22"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20.9996 22V6C20.9996 4.11438 20.9996 3.17157 20.4138 2.58579C19.828 2 18.8852 2 16.9996 2H14.9996C13.1139 2 12.1711 2 11.5853 2.58579C11.1138 3.05733 11.0218 3.76022 11.0039 5"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M15 22V9C15 7.11438 15 6.17157 14.4142 5.58579C13.8284 5 12.8856 5 11 5H7C5.11438 5 4.17157 5 3.58579 5.58579C3 6.17157 3 7.11438 3 9V22"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M9 22V19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6 8H12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6 11H12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6 14H12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default function BuildingIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={buildingSvg} {...props} />;
}
