import { FC } from "react";
import { Card } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import formatAmount from "@/helpers/format-amount";
import type { Order } from "@/features/all-orders";

const MobileOrderCard: FC<Order> = (val) => {
  const { t } = useTranslation();

  return (
    <Card
      size="small"
      style={{
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.08)",
        padding: "16px",
      }}
      bodyStyle={{ padding: 0 }}
    >
      <div className="mb-2">
        <span className="text-base whitespace-nowrap font-semibold text-[#2F3138]">
          Заказ № {val.id}
        </span>
      </div>
      <div className="mb-4">
        <span className="text-sm text-[#7D848B]">
          {t(val.type)} •{" "}
          {dayjs(val.created_at).locale("ru").format("DD MMMM, YYYY | HH:MM")}
        </span>
      </div>
      <div className="flex justify-between">
        <span>{val.payment_type.translations.ru.name}</span>
        <span className=" font-bold">{formatAmount(val.total ?? 0)} UZS</span>
      </div>
    </Card>
  );
};

export default MobileOrderCard;

export const MobileOrderCardSkeleton = () => {
  return (
    <Card
      size="small"
      style={{
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.08)",
        padding: "16px",
      }}
      bodyStyle={{ padding: 0 }}
    >
      <div className="mb-2 pt-5 rounded bg-gray-200 animate-pulse" />
      <div className="mb-4 pt-5 rounded w-[50%] bg-gray-200 animate-pulse"></div>
      <div className="flex justify-between">
        <div className="mb-2 pt-5 rounded w-[30%] bg-gray-200 animate-pulse" />
        <div className="mb-2 pt-5 rounded w-[30%] bg-gray-200 animate-pulse" />
      </div>
    </Card>
  );
};
