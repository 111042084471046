import { useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import BrocoliIcon from "@/components/brocoli-icon";
import CakeIcon from "@/components/cake-icon";
import EggsIcon from "@/components/eggs-icon";
import ChocolateIcon from "@/components/chocolate-icon";
import CoffeeIcon from "@/components/coffee-icon";
import DishIcon from "@/components/dish-icon";
import { Button } from "antd";
import type { Category } from "@/types";
import EditIcon from "@/components/edit-icon";
import { useQuery } from "@tanstack/react-query";
import { getCategories } from "@/features/all-orders";

interface ReturnType {
  btns: React.ReactElement[];
  currCategory: Category;
  setCurrCategory: React.Dispatch<React.SetStateAction<Category>>;
  setIsModalCategoryOpen: React.Dispatch<
    React.SetStateAction<Category | undefined>
  >;
}

interface UseCategoriesProps {
  initCategory?: Category;
  editButton?: boolean;
}

export default function useCategories({
  initCategory,
  editButton,
}: UseCategoriesProps): ReturnType {
  const { t } = useTranslation();

  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  console.log(currentLang);
  

  const { data: categoriesData } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      const res = await getCategories();
      return res;
    },
  });  

  // Default category, or first one from the API
  const [currCategory, setCurrCategory] = useState(
    initCategory ?? categoriesData?.results?.[0]?.translations?.en?.name ?? "Breakfast",
  );
  const [isModalCategoryOpen, setIsModalCategoryOpen] = useState<
    Category | undefined
  >(undefined);

  const btns = categoriesData?.results?.map((category) => {
    const name = category?.translations?.[currentLang]?.name; // Use the translated name
    const enName = category?.translations?.en?.name;
    const categoryId = category?.id;

    return (
      <Button
        key={name}
        size="large"
        className={clsx(
          "flex items-center justify-between w-full",
          enName === currCategory ? "bg-[#e5e7f6] text-[#5566ff]" : "",
        )}
        onClick={() => {
          // setIsModalCategoryOpen(categoryId);
          setCurrCategory(enName); // Set the current category when clicked
        }}
      >
        <span className="flex items-center gap-2">
          {getIcon(name)}
          <span>{t(name)}</span>
        </span>
        {editButton ? (
          <Button
            icon={<EditIcon />}
            className="text-[#5566ff] border-none"
            onClick={(e) => {
              e.stopPropagation(); // Prevent category selection on edit click
              setIsModalCategoryOpen(categoryId); // Set the modal to open for the clicked category
            }}
          />
        ) : null}
      </Button>
    );
  });

  return {
    btns,
    currCategory,
    setCurrCategory,
    isModalCategoryOpen,
    setIsModalCategoryOpen,
  };
}

// Function to get icons based on the category name
// function getIcon(name: string): React.ReactElement | null {
//   if (name === "Завтраки") return <EggsIcon />; // Replace with the actual Russian name if needed
//   if (name === "Обеды") return <DishIcon />;
//   if (name === "Бар") return <CoffeeIcon />;
//   if (name === "Перекурсы") return <ChocolateIcon />;
//   if (name === "ПП") return <BrocoliIcon />;
//   if (name === "Десерты") return <CakeIcon />;

//   return null;
// }
function getIcon(name: string): React.ReactElement | null {
  if (name === "Breakfast" || name === "Завтраки") return <EggsIcon />; // Replace with the actual Russian name if needed
  if (name === "Dinner" || name === "Обеды") return <DishIcon />;
  if (name === "Bar" || name === "Бар") return <CoffeeIcon />;
  if (name === "SmallFood" || name === "Перекурсы") return <ChocolateIcon />;
  if (name === "Snack" || name=== "ПП") return <BrocoliIcon />;
  if (name === "Dessert" || name === "Десерты") return <CakeIcon />;
  return null;
}
