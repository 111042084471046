import type { CustomRoute } from "@/types";
import Auth from "./views/auth";
import Login from "./views/login";

// VIEW IMPORTS

const routes: CustomRoute = {
  id: "auth",
  title: "auth",
  path: "auth",
  element: <Auth />,
  children: [
    // ROUTES
    {
      id: "login",
      title: "login",
      path: "login",
      element: <Login />,
    },
  ],
};

export default routes;
