import { Client, Order, OrdersParams } from "@/features/all-orders/types";
import { UpdateProduct } from "@/features/menu-control/types";
import { ListResponse } from "@/types";
import request from "@/utils/axios";

export async function downloadExcel(): Promise<BinaryData> {
  const res: BinaryData = await request({
    url: "/order/download_excel/",
    method: "get",
    responseType: "arraybuffer",
  });

  return res;
}

export async function getOrderRaiseStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_order_raise_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getDebtClients(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/clients/",
    method: "get",
    params,
  });

  return res;
}

export async function getOrderTypeStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_order_type_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getOrderedProductsStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_ordered_products_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getPaymentTypeStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_payment_type_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getProductCategoryStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_product_category_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getOrderExpenseStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/expenses/get_expense_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function createClient(data: Client): Promise<any> {
  const response = await request({
    url: "/clients/",
    method: "post",
    data,
  });

  return response; // Return the response data here
}


export async function payOneDebt(data: Client): Promise<any> {
  const response = await request({
    url: "/clients/debts/paid/",
    method: "post",
    data,
  });

  return response; // Return the response data here
}


export async function payAllDebt(data: Client): Promise<any> {
  const response = await request({
    url: "/clients/debts/paid/all/",
    method: "post",
    data,
  });

  return response; // Return the response data here
}

export const a = null;


export async function getDept(id: number): Promise<Order> {
  const res: Order = await request({
    url: `/clients/${id}/debtor-client/`,
    method: "get",
  });

  return res;
}

// export async function createProduct(data: NewProduct): Promise<void> {
//   await request({
//     url: "/menu/products/",
//     method: "post",
//     data,
//   });
// }

export async function updateClient(
  id: number,
  data: UpdateProduct,
): Promise<void> {
  await request({
    url: `/clients/${id}/`,
    method: "put",
    data,
  });
}