import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const chartSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g id="elements">
      <circle
        id="Ellipse 1544"
        cx="7.5"
        cy="8.5"
        r="1.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle
        id="Ellipse 1545"
        cx="13.5"
        cy="13.5"
        r="1.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <circle
        id="Ellipse 1546"
        cx="17.5"
        cy="5.5"
        r="1.5"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        id="Vector"
        d="M14.4341 12.2963L17 7M8.58251 9.56844L12.2038 12.2963M2 17L6.58957 9.87919"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector 338"
        d="M19 19H8C4.70017 19 3.05025 19 2.02513 17.9749C1 16.9497 1 15.2998 1 12V1"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default function ChartIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={chartSvg} {...props} />;
}
