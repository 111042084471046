import { message } from "antd";
import type { ArgsProps } from "antd/es/message";

interface ReturnType {
  contextHolder: React.ReactElement;
  open: (config: ArgsProps) => void;
  destroy: (key?: React.Key) => void;
}

const className = "";
const style: React.CSSProperties = {};

export default function useStyledMessage(): ReturnType {
  const [messageApi, contextHolder] = message.useMessage();

  const open = (props: ArgsProps): void => {
    void messageApi.open({
      className,
      style,
      duration: 3,
      ...props,
    });
  };

  const destroy = (key?: React.Key): void => {
    messageApi.destroy(key);
  };

  return { contextHolder, open, destroy };
}
