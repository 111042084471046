import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const circleArrowUpSvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <g id="elements">
      <circle
        id="Ellipse 1570"
        cx="10.1992"
        cy="10.5"
        r="10"
        fill="currentColor"
        fillOpacity="0.15"
      />
      <path
        id="Vector 7035"
        d="M12.6992 9L10.1992 6.5M10.1992 6.5L7.69922 9M10.1992 6.5V14.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default function CircleArrowUpIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={circleArrowUpSvg} {...props} />;
}
