import type { CustomRoute } from "@/types";
import InternalServerError from "@/views/internal-server-error";
import MenuControl from "./views";

const menuControlRoutes: CustomRoute = {
  id: "menu-control",
  title: "menu-control",
  path: "menu-control",
  element: <MenuControl />,
  errorElement: <InternalServerError />,
  children: [],
};

export default menuControlRoutes;
