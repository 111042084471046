import { colors } from "@/config/theme";
import WalkingIcon from "@/components/walking-icon";
import ChairIcon from "@/components/chair-icon";
import BuildingIcon from "@/components/building-icon";
import type { OrderType } from "@/types";

export default function OrderTypeIcon(props: {
  type: OrderType;
}): React.ReactElement | null {
  const { type } = props;

  if (type === "self") {
    return <WalkingIcon style={{ color: colors.primary }} />;
  }

  // if (type === "in-place") {
  //   return <ChairIcon style={{ color: colors.primary }} />;
  // }

  if (type === "shipping") {
    return <BuildingIcon style={{ color: colors.primary }} />;
  }

  return null;
}
