import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const deptPaySvg: React.FC = () => (
   <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
   <path d="M11.0827 7.99935C11.0827 9.14994 10.1499 10.0827 8.99935 10.0827C7.84876 10.0827 6.91602 9.14994 6.91602 7.99935C6.91602 6.84876 7.84876 5.91602 8.99935 5.91602C10.1499 5.91602 11.0827 6.84876 11.0827 7.99935Z" stroke="#5566FF" stroke-width="1.2"/>
   <path d="M14.8327 7.28485C14.5618 7.24562 14.2835 7.21572 13.9993 7.19584M3.99935 8.80469C3.71516 8.78481 3.43694 8.75491 3.16602 8.71568" stroke="#5566FF" stroke-width="1.2" strokeLinecap="round" stroke-linejoin="round"/>
   <path d="M8.99935 14.2493C7.8889 14.768 6.43024 15.0827 4.83268 15.0827C3.94441 15.0827 3.09909 14.9854 2.33268 14.8099C1.08233 14.5235 0.666016 13.7713 0.666016 12.4877V3.51099C0.666016 2.6903 1.53271 2.12662 2.33268 2.30985C3.09909 2.48539 3.94441 2.58268 4.83268 2.58268C6.43024 2.58268 7.8889 2.26799 8.99935 1.74935C10.1098 1.23071 11.5685 0.916016 13.166 0.916016C14.0543 0.916016 14.8996 1.01331 15.666 1.18885C16.9841 1.49075 17.3327 2.26632 17.3327 3.51099V12.4877C17.3327 13.3084 16.466 13.8721 15.666 13.6888C14.8996 13.5133 14.0543 13.416 13.166 13.416C11.5685 13.416 10.1098 13.7307 8.99935 14.2493Z" stroke="#5566FF" stroke-width="1.2"/>
   </svg>
);

export default function DeptPayIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={deptPaySvg} {...props} />;
}
