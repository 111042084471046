import { useMemo, useState } from "react";
import { ConfigProvider, DatePicker, Layout, Rate, Select, Table } from "antd";
import type { PaginationProps } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { colors } from "@/config/theme";
import dayjs from "dayjs";
import NoteIcon from "@/components/note-icon";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { Dayjs } from "dayjs";
import { getComplaints } from "../api";
import type { Complaint } from "../types";

const { Header, Content } = Layout;

const { RangePicker } = DatePicker;

interface Filters {
  rate: number | null;
  date: [string, string] | null;
}

export default function Complaints(): React.ReactElement {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const [filters, setFilters] = useState<Filters>({
    rate: null,
    date: null,
  });

  const {
    data: complaintsData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["complaints", { ...filters, date: getDateVal(filters.date) }],
    queryFn: async () => {
      let minDate, maxDate;

      if (filters.date !== null) {
        const [minDateStr, maxDateStr] = filters.date;

        if (minDateStr !== "" && maxDateStr !== "") {
          minDate = minDateStr;
          maxDate = maxDateStr;
        }
      }

      const res = await getComplaints({
        rate: filters.rate ?? undefined,
        min_date: minDate,
        max_date: maxDate,
      });
      return res;
    },
  });

  const complaints = complaintsData?.results;

  const paginationProps: PaginationProps = {
    defaultCurrent: page,
    total: complaints?.length ?? 0,
  };

  const onPageChange: TableProps<Complaint>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
  };

  const columns = useMemo(() => {
    const allColumns: ColumnsType<Complaint> = [
      {
        key: "created_at",
        dataIndex: "created_at",
        render(value) {
          return dayjs(value).format("DD.MM.YYYY");
        },
      },
      {
        key: "rate",
        dataIndex: "rate",
        render(value: string) {
          return <Rate value={+value} disabled />;
        },
      },
      {
        key: "phone",
        dataIndex: "phone",
        render(value) {
          return value ?? t("anonymous");
        },
      },
      {
        key: "comment",
        dataIndex: "comment",
        render(value: boolean) {
          return value;
        },
        width: "50%",
      },
    ];

    return allColumns;
  }, [t]);

  return (
    <>
      <Header className="bg-white flex items-center justify-between border-y-[1px]">
        <h1 className="text-2xl font-semibold">{t("orders-history")}</h1>

        <div className="flex items-center gap-2">
          <Select
            className="w-44"
            placeholder={t("rating")}
            allowClear
            options={[
              { label: <Rate value={1} disabled />, value: 1 },
              { label: <Rate value={2} disabled />, value: 2 },
              { label: <Rate value={3} disabled />, value: 3 },
              { label: <Rate value={4} disabled />, value: 4 },
              { label: <Rate value={5} disabled />, value: 5 },
            ]}
            onChange={(val: number | undefined) => {
              setFilters({ ...filters, rate: val ?? null });
            }}
          />

          <RangePicker
            placeholder={[t("from"), t("to")]}
            value={getDateVal(filters.date)}
            onChange={(_dayjsRange, formattedVals) => {
              setFilters({ ...filters, date: formattedVals });
            }}
          />
        </div>
      </Header>

      <Content className="bg-[#f5f5f5] px-[50px] py-6">
        <ConfigProvider
          renderEmpty={() => (
            <div
              className="flex items-center justify-center"
              style={{
                minHeight:
                  "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <NoteIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-complaints")}
                </span>
              </div>
            </div>
          )}
        >
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={complaints}
            onChange={onPageChange}
            pagination={paginationProps}
            showHeader={false}
            loading={isLoading || isFetching}
            className="custom-history-table"
          />
        </ConfigProvider>
      </Content>
    </>
  );
}

function getDateVal(input: null | [string, string]): [Dayjs, Dayjs] | null {
  if (input === null) return null;

  if (Array.isArray(input)) {
    const [minDate, maxDate] = input;

    if (minDate === "" || maxDate === "") {
      return null;
    }

    return input.map(dayjs) as [Dayjs, Dayjs];
  }

  return null;
}
