import {
  QueryProvider,
  AuthProvider,
  RouteProvider as Routes,
  ThemeProvider,
  LangProvider,
} from "./providers";
import "@/helpers/initialize";

export default function App(): React.ReactElement {
  return (
    <QueryProvider>
      <LangProvider>
        <ThemeProvider>
          <AuthProvider>
            <Routes />
          </AuthProvider>
        </ThemeProvider>
      </LangProvider>
    </QueryProvider>
  );
}
