import request from "@/utils/axios";
import type { BaseParams, ListResponse } from "@/types";
import type {
  CreateEmployee,
  Employee,
  IBranch,
  UpdateEmployee,
} from "../types";

export async function getEmployees(
  params?: BaseParams,
): Promise<ListResponse<Employee[]>> {
  const res: ListResponse<Employee[]> = await request({
    url: "/account/worker/",
    method: "get",
    params,
  });

  return res;
}

export async function createEmployee(data: CreateEmployee): Promise<void> {
  await request({
    url: "/account/worker/",
    method: "post",
    data,
  });
}

export async function updateEmployee(
  id: number,
  data: UpdateEmployee,
): Promise<void> {
  await request({
    url: `/account/worker/${id}/`,
    method: "put",
    data,
  });
}

export async function deleteEmployee(id: number): Promise<void> {
  await request({
    url: `/account/worker/${id}/`,
    method: "delete",
  });
}

export async function getBranches(): Promise<ListResponse<IBranch[]>> {
  const response: ListResponse<IBranch[]> = await request({
    url: "/account/branches/",
    method: "GET",
  });
  return response;
}
