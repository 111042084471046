import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const successSvg: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4205 8.82693C16.7256 9.13211 16.7256 9.6268 16.4205 9.93182L11.1794 15.1731C10.8742 15.4781 10.3797 15.4781 10.0745 15.1731L7.57953 12.6779C7.27435 12.3729 7.27435 11.8782 7.57953 11.5732C7.88455 11.268 8.37924 11.268 8.68427 11.5732L10.6269 13.5158L15.3156 8.82693C15.6208 8.52191 16.1154 8.52191 16.4205 8.82693ZM22 12C22 17.5275 17.5267 22 12 22C6.4725 22 2 17.5267 2 12C2 6.4725 6.47327 2 12 2C17.5275 2 22 6.47327 22 12ZM20.4375 12C20.4375 7.33615 16.6632 3.5625 12 3.5625C7.33615 3.5625 3.5625 7.33676 3.5625 12C3.5625 16.6638 7.33676 20.4375 12 20.4375C16.6638 20.4375 20.4375 16.6632 20.4375 12Z"
      fill="currentColor"
    />
  </svg>
);

export default function SuccessIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={successSvg} {...props} />;
}
