import InternalServerError from "@/views/internal-server-error";
import Complaints from "./views";
import canSee from "@/helpers/can-see";

const complaintsRoutes = canSee({
  id: "complaints",
  title: "complaints",
  path: "complaints",
  element: <Complaints />,
  errorElement: <InternalServerError />,
  children: [],
});

export default complaintsRoutes;
