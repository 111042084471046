import request from "@/utils/axios";
import type { ListResponse } from "@/types";
import type { Complaint, ComplaintParams } from "../types";

export async function getComplaints(
  params?: ComplaintParams,
): Promise<ListResponse<Complaint[]>> {
  const res: ListResponse<Complaint[]> = await request({
    url: "/account/comments/",
    method: "get",
    params,
  });

  return res;
}

export const a = null;
