import { useEffect, useState } from "react";
import { useAuthContext } from "@/contexts/auth-context";
import connectSocket from "@/utils/websocket";

export default function useWebSocket(): MessagePort | undefined {
  // const rerender = useReducer(() => ({}), {})[1];

  const { isAuth } = useAuthContext();

  const [ws, setWs] = useState<WebSocket>();
  const [msgPort, setMsgPort] = useState<MessagePort>();

  const reconnect = (): void => {
    if (typeof ws !== "undefined") {
      ws.close(1000);
    }

    const [websocket, messagePort] = connectSocket();

    setWs(websocket);
    setMsgPort(messagePort);
  };

  useEffect(() => {
    if (isAuth) {
      reconnect();
    }
  }, [isAuth]);

  useEffect(
    () => () => {
      if (typeof ws !== "undefined") {
        ws.close(1000);
      }
    },
    [ws],
  );

  return msgPort;
}
