import request from "@/utils/axios";
import type {
  ExpenseValues,
  NewProduct,
  UpdateProduct,
  UploadedFile,
} from "../types";
import { ListResponse } from "@/types";
import { Order, OrdersParams } from "@/features/all-orders/types";

export async function uploadFile(data: FormData): Promise<UploadedFile> {
  const res: UploadedFile = await request({
    url: "/menu/files/",
    method: "post",
    data,
  });
  return res;
}

export async function getExpenses(params?: {
  min_date: string | null;
  max_date: string | null;
  page: number;
  page_size: number;
  branch: number;
}): Promise<ListResponse<ExpenseValues[]>> {
  const res: ListResponse<ExpenseValues[]> = await request({
    url: "/expenses/",
    method: "get",
    params,
  });

  return res;
}

export async function createExpense(data: ExpenseValues): Promise<void> {
  await request({
    url: "/expenses/",
    method: "post",
    data,
  });
}

export async function updateExpense(
  id: number,
  data: ExpenseValues,
): Promise<void> {
  await request({
    url: `/expenses/${id}/`,
    method: "put",
    data,
  });
}

export async function deleteExpense(id: number): Promise<void> {
  await request({
    url: `/expenses/${id}/`,
    method: "delete",
  });
}
