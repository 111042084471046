import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { colors } from "@/config/theme";
import WarningIcon from "@/components/warning-icon";

export default function NotFound(): React.ReactElement {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div
      className="bg-white flex items-center justify-center"
      style={{ height: "calc(100vh - 64px - 64px)" }}
    >
      <div className="w-1/4 text-center flex flex-col gap-6 items-center justify-center">
        <div className="flex flex-col gap-4 items-center justify-center">
          <WarningIcon style={{ color: colors.primary }} />
          <span>{t("404-info")}</span>
        </div>

        <Button
          type="primary"
          onClick={() => {
            navigate("/orders");
          }}
        >
          {t("go-home")}
        </Button>
      </div>
    </div>
  );
}
