import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const moneySvg: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
  >
    <g id="elements">
      <path
        id="Ellipse 1574"
        d="M13.5 10C13.5 11.3807 12.3807 12.5 11 12.5C9.61929 12.5 8.5 11.3807 8.5 10C8.5 8.61929 9.61929 7.5 11 7.5C12.3807 7.5 13.5 8.61929 13.5 10Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        id="Vector"
        d="M18 9.14202C17.6749 9.09494 17.341 9.05906 17 9.03521M5 10.9658C4.65897 10.942 4.32511 10.9061 4 10.859"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        id="Vector_2"
        d="M11 17.5C9.66746 18.1224 7.91707 18.5 6 18.5C4.93408 18.5 3.91969 18.3833 3 18.1726C1.49957 17.8289 1 16.9264 1 15.386V4.61397C1 3.62914 2.04003 2.95273 3 3.1726C3.91969 3.38325 4.93408 3.5 6 3.5C7.91707 3.5 9.66746 3.12236 11 2.5C12.3325 1.87764 14.0829 1.5 16 1.5C17.0659 1.5 18.0803 1.61675 19 1.8274C20.5817 2.18968 21 3.12036 21 4.61397V15.386C21 16.3709 19.96 17.0473 19 16.8274C18.0803 16.6167 17.0659 16.5 16 16.5C14.0829 16.5 12.3325 16.8776 11 17.5Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </g>
  </svg>
);

export default function MoneyIcon(
  props: Partial<CustomIconComponentProps>,
): React.ReactElement {
  return <Icon component={moneySvg} {...props} />;
}
