/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import arrowLeftIcon from "@/assets/arrow-left.svg";
import DishIcon from "@/components/dish-icon";
import DishImage from "@/components/dish-image";
import SuccessIcon from "@/components/success-icon";
import { colors } from "@/config/theme";
import formatAmount from "@/helpers/format-amount";
import useCategories from "@/hooks/use-categories";
import useStyledMessage from "@/hooks/use-styled-message";
import type { ClientType, OrderType } from "@/types";
import queryClient from "@/utils/query-client";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Modal,
  Select,
  Switch,
  Tabs,
} from "antd";
// import locale from "antd/locale/ru_RU";
import ruRU from "antd/locale/ru_RU";
import enUS from "antd/locale/en_US";
import clsx from "clsx";
import dayjs from "dayjs";
import { isEmpty, uid } from "radash";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import type { Props as MaskProps } from "react-input-mask";
import Mask from "react-input-mask";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { createOrder, editOrder, getCategories, getOrder } from "../../api";
import Counter from "../../components/counter";
import type { EditOrderData } from "../../types";

import "dayjs/locale/ru";
import SearchIcon from "@/components/search-icon";

const { Header, Content } = Layout;

const getInitState = (cartId: string | null): State => {
  const carts: State = JSON.parse(localStorage.getItem("carts"));
  if (carts !== null && !cartId) {
    return carts;
  }
  if (carts !== null && typeof cartId === "string") {
    return { [cartId]: {}, ...carts };
  }
  if (carts === null && typeof cartId === "string") {
    return { [cartId]: {} };
  }
  return {};
};

interface Action {
  type: "increment" | "decrement" | "delete" | "add" | "remove";
  id: number;
  cartId: string;
  newState?: State;
}

type ProductID = number;
type Count = number | undefined;
type CartID = string;
type OrderDetails = Record<ProductID, Count>;
type State = Record<CartID, OrderDetails>;

const weekdays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const cart2Num: Record<string, number> = {};

export default function CreateOrder(): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { contextHolder, open } = useStyledMessage();
  const { btns, currCategory } = useCategories({ editButton: false });
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const cartId = searchParams.get("cart_id");

  const [counter, setCounter] = useState(1);
  const [state, dispatch] = useReducer(reducer, getInitState(cartId));
  const [currCart, setCurrCart] = useState<string>("");
  const cart = state[currCart];
  const [status, setStatus] = useState(false);

  const { length } = Object.keys(state);

  useEffect(() => {
    setCurrCart(Object.keys(state).at(-1));
  }, [length]);

  useEffect(() => {
    if (isEmpty(getInitState(cartId))) {
      const newId = uid(6);
      dispatch({ type: "add", newState: { [newId]: {} }, id: 0, cartId: "" });
      setCurrCart(newId);
      cart2Num[newId] = counter;
      setCounter(counter + 1);
      return;
    }
    if (typeof cartId === "string") {
      setCurrCart(cartId);
      cart2Num[cartId] = counter;
      setCounter(counter + 1);
    }
    if (!isEmpty(getInitState(cartId))) {
      setCounter(Object.keys(getInitState(cartId)).length + 1);
    }
  }, []);

  const [orderTypeVal, setOrderTypeVal] = useState<
    Record<string, string | undefined>
  >({});
  const [clientVal, setClientVal] = useState<
    Record<string, string | undefined>
  >({});
  const [createdDateVal, setCreatedDateVal] = useState<any>({});

  const [block, setBlock] = useState<Record<string, string | undefined>>({});
  const [office, setOffice] = useState<Record<string, string | undefined>>({});
  const [phone, setPhone] = useState<Record<string, string | undefined>>({});
  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const form = Form.useForm()[0];
  const [payModalId, setPayModalId] = useState<number>();
  const { i18n } = useTranslation();
  // const [createdDateVal, setCreatedDateVal] = React.useState({});
  // const currCart = "someCart";
  const [navigation, setNavigation] = useState<
    Record<string, string | undefined>
  >({});
  const [searchTerm, setSearchTerm] = useState("");

  const locale = useMemo(() => {
    switch (i18n.language) {
      case "ru":
        return ruRU;
      case "en":
      default:
        return enUS;
    }
  }, [i18n.language]);

  const orderCreation = useMutation({
    mutationFn: createOrder,
    onSuccess: (data) => {
      console.log("Success:", data);

      void queryClient.invalidateQueries(["orders"]);
      navigate("/orders");
    },
    onError: (error: { data: string[] }) => {
      open({
        type: "error",
        content: error?.data[0],
      });
    },
  });

  const orderUpdate = useMutation({
    mutationFn: async (data: { id: number; payload: EditOrderData }) => {
      await editOrder(data.id, data.payload);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries(["orders"]);
      void queryClient.invalidateQueries(["order", id]);
      navigate("/orders?update-success=true");
    },
    onError: (error: { data: string[] }) => {
      open({
        type: "error",
        content: error?.data[0],
      });
    },
  });

  // const { data: categoriesData } = useQuery({
  //   queryKey: ["categories"],
  //   queryFn: async () => {
  //     const res = await getCategories();
  //     return res;
  //   },
  // });

  const { data: categoriesData } = useQuery({
    queryKey: ["categories", searchTerm],
    queryFn: async () => {
      const res = await getCategories({ product_name: searchTerm });
      return res;
    },
    // enabled: !!searchTerm, // faqat inputda qiymat mavjud bo'lsa so'rov yuboriladi
  });

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  const categories = categoriesData?.results;

  const Breakfast = categories?.find((category) => category.id === 3);

  const Dinner = categories?.find((category) => category.id === 4);

  const Bar = categories?.find((category) => category.id === 2);

  const SmallFood = categories?.find((category) => category.id === 1);

  const Snack = categories?.find((category) => category.id === 5);

  const Dessert = categories?.find((category) => category.id === 6);

  const data = {
    Breakfast,
    Dinner,
    Bar,
    SmallFood,
    Snack,
    Dessert,
  };

  const { data: order } = useQuery({
    queryKey: ["order", id],
    queryFn: async () => {
      const res = await getOrder(+id);
      return res;
    },
    enabled: !!id,
  });

  const isEmptyCart = !Object.keys(cart ?? {}).some((key) => !!cart[+key]);

  const allInfoProvided = (() => {
    if (id && cartId === currCart) {
      return true;
    }

    if (orderTypeVal[currCart] === "shipping") {
      return (
        !!block[currCart] &&
        !!office[currCart] &&
        phone[currCart]?.length > 16 &&
        !!navigation[currCart]
      );
    }

    if (orderTypeVal[currCart] === "self") {
      return true;
    }

    return false;
  })();

  const onCreateOrder = (): void => {
    const products = Object.keys(cart)
      .filter((key) => !!cart[+key])
      .map((key) => ({ product: +key, qty: cart[+key] as number }));

    // edit order
    if (id && cartId === currCart) {
      orderUpdate.mutate({ id: +id, payload: { products } });
    } else {
      orderCreation.mutate({
        products,
        type: orderTypeVal[currCart] as OrderType,
        payment_status: "not_paid",
        created_at: createdDateVal[currCart] ?? dayjs().format("YYYY-MM-DD"),
        block: block[currCart],
        phone: phone[currCart],
        cabinet: office[currCart],
        position: navigation[currCart],
        source: "web_admin",
      });
      // const clientType = clientVal[currCart] as ClientType;
      // if (clientType) {
      //   // If clientType (isDept) is not empty, open the modal
      //   setIsPayModalOpen(true);
      // } else {

      // }
      dispatch({ type: "remove", cartId: currCart, id: 0 });
    }
  };

  const calcTotalPrice = (): number => {
    const keys = Object.keys(cart).filter((key) => !!cart[+key]);

    return keys.reduce((total, key) => {
      const qty = cart[+key] as number;

      const initData = { price: 0 };

      const { price } =
        categories?.reduce((product, category) => {
          const foundProduct = category.product.find(
            (dish) => dish.id === +key,
          );

          if (foundProduct) {
            return foundProduct;
          }

          return product;
        }, initData) ?? initData;

      return total + qty * price;
    }, 0);
  };

  useEffect(() => {
    if (id && order && cartId) {
      const newCartState: State =
        order?.items.reduce((_state: State, currItem) => {
          _state[currItem.product.id] = currItem.qty;
          return _state;
        }, {}) ?? {};

      const newState = { ...state, [cartId]: newCartState };

      dispatch({ type: "add", newState, id: +id, cartId });
    }
  }, [id, searchParams, order]);

  useEffect(() => {
    if (id && cartId) {
      const createOnlyCarts = Object.keys(state)
        .filter((_cartId) => _cartId !== cartId)
        .reduce((copy: State, key) => {
          // eslint-disable-next-line no-param-reassign
          copy[key] = state[key];
          return copy;
        }, {});

      localStorage.setItem("carts", JSON.stringify(createOnlyCarts));
    } else {
      localStorage.setItem("carts", JSON.stringify(state));
    }
  }, [state, id]);

  console.log(state, cart, currCart, counter);

  if (typeof cart === "undefined") return <> </>;

  const handlePayCancel = (): void => {
    setIsPayModalOpen(false);
    setPayModalId(undefined);
    form.resetFields();
  };

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };
  return (
    <>
      {contextHolder}
      <Header className="bg-[#f5f5f5] flex items-center border-y-[1px] p-0 pl-[50px]">
        <div className="w-2/3 flex items-center gap-4 overflow-x-auto">
          <img
            src={arrowLeftIcon}
            alt="arrow-left"
            className="cursor-pointer"
            aria-hidden
            onClick={() => {
              navigate("/orders");
            }}
          />
          <div className="flex">
            {Object.keys(state).map((cartID, index) => (
              <div
                key={cartID}
                className={clsx(
                  "cursor-pointer h-16 px-4 flex items-center gap-4 flex-shrink-0",
                  currCart === cartID ? "bg-white" : "",
                )}
                onClick={() => {
                  setCurrCart(cartID);
                }}
                aria-hidden
              >
                <span className="h-16">
                  {t("cart")} №{cart2Num[cartID] ?? index + 1}
                </span>
                <CloseOutlined
                  onClick={() => {
                    if (Object.keys(state).length > 1) {
                      dispatch({ type: "remove", cartId: cartID, id: 0 });
                    }
                  }}
                />
              </div>
            ))}

            <div
              className="bg-[#5566ff] cursor-pointer h-16 px-4 text-white w-16 flex items-center justify-center"
              onClick={() => {
                const clone = structuredClone(state);
                const newId = uid(6);
                dispatch({
                  type: "add",
                  newState: { ...clone, [newId]: {} },
                  id: 0,
                  cartId: "",
                });
                setCurrCart(newId);
                cart2Num[newId] = counter;
                setCounter(counter + 1);
              }}
              aria-hidden
            >
              <PlusOutlined style={{ fontSize: "2rem" }} />
            </div>
          </div>
        </div>
        <div
          className="border-l-[1px] h-full flex items-center bg-white"
          style={{
            width: "calc(33.333333% + 52px)",
          }}
        >
          <div className="flex items-center gap-2 font-semibold text-2xl pl-6">
            {id && cartId === currCart
              ? `${t("order")} #${id}`
              : t("new-order")}
          </div>
        </div>
      </Header>

      <Content className="px-[50px] bg-white flex">
        <div
          className="w-2/3 pr-6 overflow-y-auto"
          style={{ height: "calc(100vh - 64px - 64px)" }}
        >
          <div className="flex justify-between gap-4 mb-6 pt-6 bg-white sticky top-0 z-[1]">
            {btns}
          </div>
          <div>
            <div className="flex gap-6">
              <Input
                className="w-full py-3"
                placeholder={t("search") ?? ""}
                prefix={<SearchIcon />}
                onChange={handleInputChange} // Har bir harf kiritilishi bilan so'rov yuboriladi
              />
            </div>
          </div>
          {currCategory !== "Dinner" && data[currCategory]?.product?.length > 0
            ? data[currCategory]?.product
                ?.filter((product) => product.status)
                ?.map((dish) => (
                  <div
                    key={dish.id}
                    className="border rounded-lg my-4 p-2 flex items-center gap-4 cursor-pointer hover:border-[#5566ff] transition"
                  >
                    <Checkbox
                      checked={!!cart[dish.id]}
                      onChange={(e) => {
                        if (e.target.checked) {
                          dispatch({
                            type: "increment",
                            id: dish.id,
                            cartId: currCart,
                          });
                        } else {
                          dispatch({
                            type: "delete",
                            id: dish.id,
                            cartId: currCart,
                          });
                        }
                      }}
                    />

                    <DishImage src={dish.image} width={65} height={65} />

                    <div className="flex-1 flex flex-col">
                      <span>{dish.translations?.[i18n.language]?.name}</span>
                      <span className="text-[#7D848B]">{dish.status}</span>
                    </div>

                    <span>{formatAmount(dish.price)} UZS</span>

                    <Counter
                      count={cart[dish.id]}
                      onDecrement={() => {
                        dispatch({
                          type: "decrement",
                          id: dish.id,
                          cartId: currCart,
                        });
                      }}
                      onIncrement={() => {
                        const maxCount = dish.qty;
                        const inCartCount = cart[dish.id] ?? 0;

                        if (inCartCount < maxCount) {
                          dispatch({
                            type: "increment",
                            id: dish.id,
                            cartId: currCart,
                          });
                        } else {
                          open({
                            type: "error",
                            content: t("dish-count-reached-max"),
                          });
                        }
                      }}
                    />
                  </div>
                ))
            : // <div className="flex justify-center items-center h-[400px] my-4">
              //   {currCategory ? (
              //     <div className="flex flex-col justify-center items-center max-w-[300px] text-center">
              //       <div>
              //         <DishIcon style={{ color: colors.primary }} />
              //       </div>
              //       <span className="text-base font-normal">
              //         {t("not-found")}
              //       </span>
              //       <span className="text-base font-normal">
              //         {t("not-added")}
              //       </span>
              //     </div>
              //   ) : (
              //     <span className="text-base font-normal">
              //       {t("no-category-selected")}
              //     </span>
              //   )}
              // </div>
              ""}

          {data[currCategory]?.product?.filter((product) => product.status)
            .length === 0 ? (
            <div
              className="flex items-center justify-center"
              style={{
                minHeight:
                  "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px - 64px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <DishIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-dish")}
                </span>
              </div>
            </div>
          ) : null}

          {currCategory === "Dinner" && (
            <ConfigProvider
              locale={locale}
              theme={{
                components: {
                  Tabs: {
                    cardBg: "white",
                  },
                },
              }}
            >
              <Tabs
                className="mt-2"
                // onChange={onChange}
                // className="bg-red-400"
                // tabBarStyle={{}}
                hideAdd
                type="card"
                defaultActiveKey={weekdays[dayjs().day() - 1]}
                items={weekdays.map((day) => ({
                  label:
                    day === weekdays[dayjs().day() - 1]
                      ? `${t(day)} (${t("today")})`
                      : t(day),
                  key: day,
                  children: (
                    <div className="bg-[#f5f5f5] p-2 rounded-r-lg rounded-bl-lg">
                      {data[currCategory]?.product
                        ?.filter((product) => product.day === day)
                        ?.filter((product) => product.status)
                        ?.map((dish) => (
                          <div
                            key={dish.id}
                            className="border rounded-lg m-4 p-2 flex items-center gap-4 cursor-pointer hover:border-[#5566ff] transition bg-white"
                          >
                            <Checkbox
                              checked={!!cart[dish.id]}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  dispatch({
                                    type: "increment",
                                    id: dish.id,
                                    cartId: currCart,
                                  });
                                } else {
                                  dispatch({
                                    type: "delete",
                                    id: dish.id,
                                    cartId: currCart,
                                  });
                                }
                              }}
                            />

                            <DishImage
                              src={dish.image}
                              width={65}
                              height={65}
                            />

                            <div className="flex-1 flex flex-col">
                              <span>{dish.translations?.[i18n.language]?.name}</span>
                              <span className="text-[#7D848B]">
                                {dish.status}
                              </span>
                            </div>

                            <Counter
                              count={cart[dish.id]}
                              onDecrement={() => {
                                dispatch({
                                  type: "decrement",
                                  id: dish.id,
                                  cartId: currCart,
                                });
                              }}
                              onIncrement={() => {
                                const maxCount = dish.qty;
                                const inCartCount = cart[dish.id] ?? 0;

                                if (inCartCount < maxCount) {
                                  dispatch({
                                    type: "increment",
                                    id: dish.id,
                                    cartId: currCart,
                                  });
                                } else {
                                  open({
                                    type: "error",
                                    content: t("dish-count-reached-max"),
                                  });
                                }
                              }}
                            />
                          </div>
                        ))}

                      {data[currCategory]?.product
                        ?.filter((product) => product.day === day)
                        ?.filter((product) => product.status).length === 0 ? (
                        <div
                          className="flex items-center justify-center"
                          style={{
                            minHeight:
                              "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px - 64px)",
                          }}
                        >
                          <div className="flex flex-col items-center gap-2">
                            <DishIcon style={{ color: colors.primary }} />
                            <span className="text-base leading-6 font-normal text-[#2F3138]">
                              {t("no-dish-for-this-day")}
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ),
                }))}
              />
            </ConfigProvider>
          )}
        </div>
        <div className="w-1/3 border-l-[1px] p-6 pr-0 relative">
          {!(Object.keys(cart).filter((key) => !!cart[+key]).length > 0) ? (
            <div
              className="flex flex-col items-center justify-center gap-2"
              style={{
                height: "calc(100vh - 64px - 64px - 24px - 24px - 343px)",
              }}
            >
              <DishIcon style={{ color: colors.primary }} />
              <span className="text-[#7D848B]">{t("start-adding-dish")}</span>
            </div>
          ) : (
            <div
              className="overflow-y-hidden hover:overflow-y-auto absolute w-full"
              style={{
                height: "calc(100vh - 64px - 64px - 24px - 24px - 343px)",
              }}
            >
              {Object.keys(cart)
                .filter((key) => !!cart[+key])
                .map((key) => {
                  const initData = {
                    price: 0,
                    image: "",
                    translations: { ru: { name: "" } },
                    qty: 0,
                  };

                  const { price, image, translations, qty } =
                    categories?.reduce((product, category) => {
                      const foundProduct = category.product.find(
                        (dish) => dish.id === +key,
                      );

                      if (foundProduct) {
                        return foundProduct;
                      }

                      return product;
                    }, initData) ?? initData;

                  return (
                    <div key={key} className="flex gap-2 mb-4">
                      <DishImage src={image} />

                      <div className="flex-1 flex flex-col justify-around">
                        <div className="flex justify-between">
                          <span className="text-sm leading-4 font-medium w-60 whitespace-nowrap text-ellipsis overflow-hidden">
                            {translations?.[i18n.language]?.name}
                          </span>
                          <span className="text-sm leading-4 font-semibold whitespace-nowrap">
                            {formatAmount(price)} UZS
                          </span>
                        </div>
                        <div className="flex justify-between items-center">
                          <Counter
                            count={cart[+key]}
                            onIncrement={() => {
                              const maxCount = qty;
                              const inCartCount = cart[+key] ?? 0;

                              if (inCartCount < maxCount) {
                                dispatch({
                                  type: "increment",
                                  id: +key,
                                  cartId: currCart,
                                });
                              } else {
                                open({
                                  type: "error",
                                  content: t("dish-count-reached-max"),
                                });
                              }
                            }}
                            onDecrement={() => {
                              dispatch({
                                type: "decrement",
                                id: +key,
                                cartId: currCart,
                              });
                            }}
                          />

                          <button
                            type="button"
                            className="bg-transparent text-[#FF1F00] hover:text-red-400 transition active:text-red-500"
                            onClick={() => {
                              dispatch({
                                type: "delete",
                                id: +key,
                                cartId: currCart,
                              });
                            }}
                          >
                            {t("delete")}
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}

          <div
            className="bg-[#f5f5f5] rounded-t-2xl absolute bottom-0 w-full left-0 p-6"
            style={{ width: "calc(100% + 50px)" }}
          >
            <div className="flex flex-col gap-4">
              <div className="flex justify-between items-center">
                <span>{t("client-id")}</span>
                <span>{t("undefined")}</span>
              </div>
              <div className="flex justify-between items-center">
                <span>{t("total-position-count")}</span>
                <span>
                  {Object.keys(cart).filter((key) => !!cart[+key]).length}{" "}
                  {t("positions")}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span>{t("order-type")}</span>
                <span>
                  {id && currCart === cartId ? (
                    t(order?.type ?? "")
                  ) : (
                    <Select
                      className="w-36"
                      placeholder={t("choose_select")}
                      options={[
                        { label: t("self"), value: "self" },
                        { label: t("shipping"), value: "shipping" },
                      ]}
                      value={orderTypeVal[currCart]}
                      onChange={(val) => {
                        setOrderTypeVal({ ...orderTypeVal, [currCart]: val });
                      }}
                    />
                  )}
                </span>
              </div>
              {/* <div className="flex justify-between items-center">
                <span>{t("Способ оплаты")}</span>
                <span>
                  {id && currCart === cartId ? (
                    t(order?.type ?? "")
                  ) : (
                    <Select
                      className="w-36"
                      placeholder={t("choose")}
                      options={[{ label: t("dept"), value: "dept" }]}
                      value={clientVal[currCart]}
                      onChange={(val) => {
                        setClientVal({ ...clientVal, [currCart]: val });
                      }}
                    />
                  )}
                </span>
              </div> */}
              <div className="flex justify-between items-center">
                <span>{t("fill_date")}</span>
                <span>
                  <ConfigProvider locale={locale}>
                    <DatePicker
                      onChange={(_, stringDate) => {
                        setCreatedDateVal({
                          ...createdDateVal,
                          [currCart]: stringDate,
                        });
                      }}
                    />
                  </ConfigProvider>
                </span>
              </div>

              {orderTypeVal[currCart] === "shipping" ? (
                <>
                  <div className="flex justify-between">
                    <span>{t("block-office")}</span>
                    <span className="flex gap-2">
                      <Input
                        placeholder={t("block-number") ?? ""}
                        className="w-20"
                        onChange={(e) => {
                          setBlock({ ...block, [currCart]: e.target.value });
                        }}
                        value={block[currCart]}
                      />
                      <Input
                        placeholder={t("office-number") ?? ""}
                        className="w-32"
                        onChange={(e) => {
                          setOffice({ ...office, [currCart]: e.target.value });
                        }}
                        value={office[currCart]}
                      />
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span>{t("office-location")}</span>
                    <span>
                      <Input
                        onChange={(e) => {
                          setNavigation({
                            ...navigation,
                            [currCart]: e.target.value,
                          });
                        }}
                        value={navigation[currCart]}
                      />
                    </span>
                  </div>
                </>
              ) : null}

              {orderTypeVal[currCart] === "shipping" &&
              typeof order?.phone === "string" &&
              order?.phone !== "" ? (
                <div className="flex justify-between">
                  <span>{t("phone")}</span>
                  <span>{order.phone}</span>
                </div>
              ) : null}

              {orderTypeVal[currCart] === "shipping" &&
              (typeof order?.phone !== "string" ||
                (order?.phone === "" && !id) ||
                !!cartId) ? (
                <div className="flex justify-between">
                  <span>{t("phone")}</span>
                  <span>
                    <Mask
                      maskChar={null}
                      placeholder="+998 XX XXX XXXX"
                      mask="+\9\98 99 999 99 99"
                      onChange={(e) => {
                        setPhone({ ...phone, [currCart]: e.target.value });
                      }}
                      value={phone[currCart] ?? ""}
                    >
                      {(maskProps: MaskProps) => <Input {...maskProps} />}
                    </Mask>
                  </span>
                </div>
              ) : null}
            </div>

            <Divider />

            <div className="flex items-center justify-between">
              <span className="text-base leading-5 font-semibold">
                {t("total-price")}
              </span>
              <span className="text-2xl leading-7 font-bold">
                {formatAmount(calcTotalPrice())} UZS
              </span>
            </div>

            <Button
              type="primary"
              size="large"
              className="w-full flex items-center justify-center mt-6"
              disabled={isEmptyCart || !allInfoProvided}
              onClick={onCreateOrder}
              loading={orderCreation.isLoading}
            >
              <SuccessIcon />
              {id && cartId === currCart ? t("edit-order") : t("create-order")}
            </Button>
          </div>

          {/* <OrderActions
          userPaid={false}
          order={}
          onPay={}
          /> */}
        </div>
      </Content>
      <Modal
      title="Записать как долг"
      open={isPayModalOpen}
      footer={null}
      centered
      onCancel={handlePayCancel}
      width={450}
    >
      <Form
        name="menu-control-form"
        layout="vertical"
        autoComplete="off"
        form={form}
        requiredMark={false}
        className="pb-6"
      >
        <div className="flex justify-between gap-4 my-4 border-b-[1px]"></div>

        {/* Conditionally render form fields based on status */}
        {status ? (
          <>
            {/* When the switch is checked (New Client) */}
            <Form.Item
              label={t("client-name")}
              name="client_name"
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 3,
                  whitespace: true,
                  message: t("field-min-3") ?? "",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("client-surname")}
              name="client_surname"
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 3,
                  whitespace: true,
                  message: t("field-min-3") ?? "",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("phone_number")}
              name="client_phone"
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 3,
                  whitespace: true,
                  message: t("field-min-3") ?? "",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </>
        ) : (
          <>
            {/* When the switch is unchecked (Existing Client) */}
            <Form.Item
              label={t("Выберите клиента")}
              name="existing_client"
              rules={[
                {
                  required: true,
                  type: "string",
                  min: 3,
                  whitespace: true,
                  message: t("field-min-3") ?? "",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Выбрать"
                optionFilterProp="label"
                onChange={(val) => {
                  console.log(val); // Handle the selected value
                }}
                options={[
                  {
                    value: "jack",
                    label: "Jack",
                  },
                  {
                    value: "lucy",
                    label: "Lucy",
                  },
                  {
                    value: "tom",
                    label: "Tom",
                  },
                ]}
              />
            </Form.Item>
          </>
        )}

        <div className="flex justify-between gap-6">
          <ConfigProvider theme={{ token: { colorPrimary: "#2BC128" } }}>
            <Form.Item
              name="status"
              valuePropName="checked"
              className="flex items-center gap-4"
            >
              <Switch
                size="large"
                defaultChecked={status}
                onChange={(val) => {
                  setStatus(val); // Update the status state
                }}
              />
              <span className="ps-4 font-medium text-sm">Новый клиент</span>
            </Form.Item>
          </ConfigProvider>

          <Button
            type="primary"
            htmlType="submit"
            className="flex items-center bg-[#5566FF]"
            size="large"
          >
            Завершить
          </Button>
        </div>
      </Form>
    </Modal>

    </>
  );
}

function reducer(_state: State, action: Action): State {
  const state = structuredClone(_state);

  if (action.type === "increment") {
    const cart = state[action.cartId];
    if (typeof cart[action.id] === "number") {
      cart[action.id] += 1;
      return state;
    }
    cart[action.id] = 1;
    return state;
  }

  if (action.type === "decrement") {
    const cart = state[action.cartId];
    if (typeof cart[action.id] !== "undefined" && cart[action.id] > 0) {
      cart[action.id] -= 1;
      return state;
    }
    cart[action.id] = 0;
    return state;
  }

  if (action.type === "delete") {
    state[action.cartId][action.id] = undefined;
    return state;
  }

  if (action.type === "add") {
    if (typeof action.newState !== "undefined") {
      return action.newState;
    }
  }

  if (action.type === "remove") {
    delete state[action.cartId];
    return state;
  }

  return state;
}
