/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Layout,
  Modal,
  Radio,
  Select,
  Space,
  Table,
  Tabs,
  TabsProps,
  Tag,
} from "antd";
import type { PaginationProps } from "antd";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getOrders } from "@/features/all-orders";
import { colors } from "@/config/theme";
import { uid } from "radash";
import dayjs from "dayjs";
import formatAmount from "@/helpers/format-amount";
import NoteIcon from "@/components/note-icon";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { Order } from "@/features/all-orders";
import type { OrderType } from "@/types";
import DownloadIcon from "@/components/download-icon";
import clsx from "clsx";
import { createClient, getDebtClients, updateClient } from "../../api";
import OrderTypeIcon from "@/components/order-type-icon";
import "dayjs/locale/ru";
import TotalStatsCard from "../components/total-stats-card";
import SalesChart from "../components/sales-chart";
import PaymentChart from "../components/payment-chart";
import PreferencesIcon from "@/components/preferences-icon";
import LogoutIcon from "@/components/logout-icon";
import { useAuthContext } from "@/contexts/auth-context";
import MobileOrderCard, {
  MobileOrderCardSkeleton,
} from "../components/mobile-order-card";
import EggsIcon from "@/components/eggs-icon";
import DishIcon from "@/components/dish-icon";
import CoffeeIcon from "@/components/coffee-icon";
import ChocolateIcon from "@/components/chocolate-icon";
import BrocoliIcon from "@/components/brocoli-icon";
import CakeIcon from "@/components/cake-icon";
import MoneyIcon from "@/components/money-icon";
import { useNavigate } from "react-router-dom";
import AddIcon from "@/components/add-icon";
import SearchIcon from "@/components/search-icon";
import BaseClientIcon from "@/components/base-client-icon";
import NoteDeptIcon from "@/components/note-debt-icon";
import DeptPayIcon from "@/components/dept-pay-icon";
import { NewProduct } from "@/features/menu-control/types";
import queryClient from "@/utils/query-client";
import HistoryDeptIcon from "@/components/history-icon";
import ReactInputMask from "react-input-mask";

const { Header, Content } = Layout;

const { RangePicker } = DatePicker;

interface Filters {
  self: boolean;
  shipping: boolean;
  min_date: string | null;
  max_date: string | null;
  payment_type: number | undefined;
  date_type: string | undefined;
}

export default function ClientsPage(): React.ReactElement {
  const { t } = useTranslation();
  const { setIsAuth } = useAuthContext();
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [productsPageSize, setProductsPageSize] = useState(10);
  const [productsPage, setProductsPage] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalId, setModalId] = useState<number>();

  const [open, setOpen] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("orders-list");
  const form = Form.useForm()[0];
  const [filters, setFilters] = useState<Filters>({
    self: true,
    shipping: true,
    min_date: null,
    max_date: null,
    date_type: "day",
    payment_type: undefined,
  });

  const [filtersDraft, setFiltersDraft] = useState<Filters>({
    search: "",
  });

  const createMutation = useMutation({
    mutationFn: async (payload: ClientData) => {
      if (typeof modalId === "undefined") {
        // Create a new client (POST request)
        await createClient(payload);
      } else {
        // Update existing client (PUT request)
        await updateClient(modalId, payload);
      }
    },
    onSuccess: () => {
      setIsModalOpen(false);
      form.resetFields();
      void queryClient.invalidateQueries(["clients"]);

      void queryClient.invalidateQueries([
        "orders",
        filtersDraft,
        page,
        pageSize,
      ]);

      setModalId(undefined);
    },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  useEffect(() => {
    if (isModalOpen) {
      // Set the initial value of phone_number to "+998" when the modal opens
      form.setFieldsValue({ phone_number: "+998" });
    }
  }, [isModalOpen, form]);

  const submitHandler = (values: FormValues): void => {
    console.log("Form values:", values);
    const phone_number = values.phone_number.replace("+", "");
    const clientData = {
      first_name: values.first_name,
      last_name: values.last_name,
      phone_number,
    };

    // Directly send the client data (no file handling needed)
    createMutation.mutate(clientData);
  };

  const defaultFilters = {
    payment_type: filtersDraft.payment_type,
  };

  const {
    data: ordersData, // ordersData should be an object if fetched correctly
    isLoading: isOrdersLoading,
    isFetching: isOrdersFetching,
  } = useQuery({
    queryKey: ["orders", filtersDraft, page, pageSize],
    queryFn: async () => {
      const finishedFilters = {
        page,
        page_size: pageSize,
        search: filtersDraft.search,
        ...defaultFilters,
      };

      const res = await getDebtClients(finishedFilters);
      return res;
    },
  });

  if (isOrdersLoading) {
    console.log("Orders data is still loading...");
  }

  const ordersPaginationProps: PaginationProps = {
    defaultCurrent: page,
    defaultPageSize: pageSize,
    total: ordersData?.count ?? 0,
    locale: { items_per_page: "" },
  };

  const onPageChange: TableProps<Order>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
    setPageSize(pagination.pageSize ?? 10);
  };

  const columns = useMemo(() => {
    const ordersColumns: ColumnsType<Order> = [
      {
        key: "full-name",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("full-name-client")}
          </span>
        ),
        dataIndex: "fullName", // Use a computed key
        render: (text, record) => {
          // Access first_name and last_name from the record
          const fullName = `${record.first_name} ${record.last_name}`;
          return (
            <span className="text-base whitespace-nowrap font-normal text-[#2F3138]">
              {fullName}
            </span>
          );
        },
      },
      {
        key: "phone-number",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("phone_number")}
          </span>
        ),
        dataIndex: "phone_number",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-normal text-[#2F3138]">
              {value}
            </span>
          );
        },
      },
      {
        key: "order-id",
        width: 350,
        title: (
          <span className="font-medium text-[#7D848B]">{t("action")}</span>
        ),
        dataIndex: "id",
        render: (text, record) => {
          return (
            <span className="flex gap-4">
              <Button
                onClick={() => {
                  handleOpenModal(record); // Pass the full record object here
                }}
                className="flex items-center bg-[#7D858B33] border-none"
              >
                <NoteDeptIcon />
                {t("update-employee")}
              </Button>
              <Button
                onClick={() => {
                  // setCurrDebt(record);
                  // detailDebt()
                  navigate(`/clients/debt-detail/${record?.id}`);
                }}
                className="flex items-center bg-[#fff] border-[#56F] text-[#5566FF]"
              >
                <HistoryDeptIcon />
                {t("history")}
              </Button>
            </span>
          );
        },
      },
    ];

    const productsColumns: ColumnsType<Order> = [
      {
        key: "name-id",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("product-name")}
          </span>
        ),
        dataIndex: "name",
        render(value: string) {
          return (
            <span className="text-base whitespace-nowrap font-semibold text-[#2F3138]">
              {value}
            </span>
          );
        },
      },
      {
        key: "category",
        title: (
          <span className="font-medium text-[#7D848B]">{t("category")}</span>
        ),
        dataIndex: "category",
        render(value) {
          function getIcon(id: number): React.ReactElement | null {
            if (id === 3) return <EggsIcon />;
            if (id === 4) return <DishIcon />;
            if (id === 2) return <CoffeeIcon />;
            if (id === 1) return <ChocolateIcon />;
            if (id === 5) return <BrocoliIcon />;
            if (id === 6) return <CakeIcon />;

            return null;
          }

          console.log(value);
          return (
            <Tag className="bg-white mr-0 py-1 px-2 flex items-center gap-1 w-fit text-xs text-[#7D848B]">
              {getIcon(value.id)}
              {value.name}
            </Tag>
          );
        },
      },
      {
        key: "count",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("sales-number")}
          </span>
        ),
        dataIndex: "count",
        render(value) {
          return (
            <span className="text-sm whitespace-nowrap text-[#2F3138]">
              {value} {t("piece")}
            </span>
          );
        },
      },
      {
        key: "price",
        title: (
          <span className="font-medium text-[#7D848B]">{t("unit-price")}</span>
        ),
        dataIndex: "price",
        render(value) {
          return (
            <span className="text-sm text-[#2F3138]">
              {formatAmount(value)} UZS
            </span>
          );
        },
      },
      {
        key: "total",
        title: (
          <span className="font-medium text-[#7D848B]">
            {t("total-price-sales")}
          </span>
        ),
        dataIndex: "total",
        render(value: number, vals) {
          return (
            <span className="text-sm text-[#2F3138] whitespace-nowrap tabular-nums">
              {formatAmount(vals.count * vals.price ?? 0)} UZS
            </span>
          );
        },
      },
    ];
    return { orders: ordersColumns, products: productsColumns };
  }, [t, activeTab]);

  const showModal = (id?: number): void => {
    if (typeof id !== "undefined") {
      setModalId(id);
    }
    setIsModalOpen(true);
  };

  const handleOpenModal = (record) => {
    setModalId(record.id);
  
    // Remove the +998 prefix if it exists
    const phoneNumberWithoutPrefix = record.phone_number.replace("+998", "");
  
    form.setFieldsValue({
      first_name: record.first_name,
      last_name: record.last_name,
      phone_number: phoneNumberWithoutPrefix, // Set phone number without the +998 prefix
    });
  
    // Open the modal
    setIsModalOpen(true);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFiltersDraft((prev) => ({
      ...prev,
      search: value, // Update search term
    }));
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const handleCancel = (): void => {
    setIsModalOpen(false);
    setModalId(undefined);
    form.resetFields();
  };

  const clients = ordersData?.count;
  return (
    <>
      <Header className="bg-white flex items-center justify-between border-y-[1px]">
        <div className="flex items-center gap-2">
          <h1 className="text-2xl font-semibold">{t("all-clients")}</h1>
          <span className="px-2 py-1 rounded-[5px] h-[23px] flex items-center text-[#7D848B] font-medium border-[#7D848B] border-[1px]">
            {clients}
          </span>
        </div>

        <div className="flex items-center gap-6">
          <Button
            type="primary"
            className="flex items-center"
            size="large"
            onClick={() => {
              showModal();
            }}
          >
            <AddIcon />
            {t("add-client")}
          </Button>
        </div>
      </Header>
      <Content className="px-4 my-4">
        <div className="">
          <div className="flex gap-6">
            <Input
              className="w-full p-3 rounded-[6px] border-none"
              placeholder={t("search-client") ?? ""}
              prefix={<SearchIcon />}
              onChange={handleInputChange} // Har bir harf kiritilishi bilan so'rov yuboriladi
            />
          </div>
        </div>
      </Content>
      <Content className="bg-[#f5f5f5] px-4 py-6">
        <ConfigProvider
          renderEmpty={() => (
            <div
              className="flex items-center justify-center"
              style={{
                height: isTabletOrMobile
                  ? "calc(100vh - 64px - 64px - 24px - 24px - 80px - 56px - 77px - 16px - 16px - 1px)"
                  : "calc(100vh - 64px - 64px - 60px - 24px - 24px - 55px - 16px - 16px - 1px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <NoteIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-reports")}
                </span>
              </div>
            </div>
          )}
        >
          {isTabletOrMobile ? (
            <h1 className="text-lg font-semibold mb-6">
              {t("orders-list")} ({ordersData?.count})
            </h1>
          ) : (
            <Table
              id="orders-list"
              rowKey={(record) => record.id}
              showHeader={true}
              columns={columns.orders}
              dataSource={ordersData?.results}
              onChange={onPageChange}
              pagination={ordersPaginationProps}
              loading={isOrdersLoading || isOrdersFetching}
              className={clsx(
                "overflow-x-auto bg-white",
                isTabletOrMobile
                  ? "custom-report-table-mobile"
                  : "custom-report-table",
                activeTab === "orders-list" ? "" : "hidden",
              )}
            />
          )}
        </ConfigProvider>
      </Content>
      <Modal
        title={
          typeof modalId !== "undefined" ? t("edit_client") : t("add-client")
        }
        open={isModalOpen}
        footer={null}
        centered
        onCancel={handleCancel}
        width={450}
      >
        <Form
          name="menu-control-form"
          layout="vertical"
          onFinish={submitHandler}
          autoComplete="off"
          form={form}
          requiredMark={false}
          className="pb-6"
        >
          <div className="flex justify-between gap-4 my-4 border-b-[1px]"></div>

          <Form.Item
            label={t("client-name")}
            name="first_name"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("client-surname")}
            name="last_name"
            rules={[
              {
                required: true,
                type: "string",
                min: 3,
                whitespace: true,
                message: t("field-min-3") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            label={t("phone_number")}
            name="phone_number"
            rules={[
              {
                required: true,
                pattern: /^\+998\d{9}$/, // Ensure phone number starts with +998 and has 9 digits after
                message: t("field-invalid-phone") ?? "",
              },
            ]}
          >
            <Input />
          </Form.Item> */}
           <Form.Item
          label={t("phone_number")}
          name="phone_number"
          rules={[
            {
              required: true,
              pattern: /^\+998\d{9}$/, // Ensure phone number starts with +998 and has 9 digits after
              message: t("field-invalid-phone") ?? "",
            },
          ]}
        >
          <Input />
        </Form.Item>
          <div className="flex justify-center gap-6">
            <Button
              type="primary"
              className="flex items-center py-[14px] px-6 bg-[#F5F5F5] text-[#2F3138] text-sm font-medium"
              size="large"
              onClick={handleCancel}
            >
              {t("cancel")}
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex items-center bg-[#5566FF]"
              size="large"
              // loading={createMutation.isLoading}
            >
              {typeof modalId !== "undefined"
                ? t("save_client")
                : t("add-client")}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

function getOrderType(filters: {
  self: boolean;
  shipping: boolean;
}): OrderType | undefined {
  const { self, shipping } = filters;

  if (self && shipping) return undefined;

  if (self) return "self";

  if (shipping) return "shipping";

  return undefined;
}
