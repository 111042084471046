/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-arrow-callback */
import { forwardRef, memo } from "react";
import { Button, Tag } from "antd";
import { useTranslation } from "react-i18next";
import queryClient from "@/utils/query-client";
import clsx from "clsx";
import { lightFormat } from "date-fns";
import { Draggable } from "react-beautiful-dnd";
import { colors } from "@/config/theme";
import OrderTypeIcon from "@/components/order-type-icon";
import NoteIcon from "@/components/note-icon";
import type { ListResponse } from "@/types";
import type { Order } from "../types";

interface KanbanProps {
  placeholder: React.ReactNode;
  title: string;
  count: number;
  orders: Order[] | undefined;
  className?: string;
  onOrderClick: (id: number) => void;
  extraBtn?: React.ReactElement;
}

function Kanban(
  props: KanbanProps,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const {
    placeholder,
    title,
    count,
    orders,
    className,
    onOrderClick,
    extraBtn,
  } = props;

  const { t } = useTranslation();

  return (
    <div ref={ref} className={clsx(className, "flex flex-col h-full")}>
      <div className="flex justify-between items-stretch">
        <span className="bg-[#f5f5f5] w-max rounded-t-lg p-2">
          {title} <span className="text-gray-500">({count})</span>
        </span>

        {typeof extraBtn !== "undefined" ? extraBtn : null}
      </div>
      <div
        className="bg-[#f5f5f5] h-full rounded-tr-lg rounded-b-lg overflow-y-hidden hover:overflow-y-auto"
        // hard-coded height for kanban card body
        style={{ height: "calc(100vh - 64px - 64px - 24px - 24px - 37px)" }}
      >
        {orders?.map((_props, index) => (
          <Draggable
            key={_props.id}
            draggableId={String(_props.id)}
            index={index}
          >
            {(provided, _snapshot) => (
              <ItemCard
                ref={provided.innerRef}
                provided={provided}
                onOrderClick={onOrderClick}
                {..._props}
              />
            )}
          </Draggable>
        ))}

        {placeholder}

        {orders?.length === 0 ? (
          <div className="h-full flex flex-col items-center justify-center text-[#7D848B]">
            <NoteIcon style={{ color: colors.primary }} />
            <span>{t("no-orders")}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}

const KanbanCard = memo(forwardRef<HTMLDivElement, KanbanProps>(Kanban));

interface ItemProps extends Order {
  onOrderClick: (id: number) => void;
  provided: any;
}

function Item(
  props: ItemProps,
  ref: React.Ref<HTMLDivElement>,
): React.ReactElement {
  const {
    provided,
    id,
    type,
    payment_status,
    delivery_status,
    created_at,
    is_new,
    status,
    onOrderClick,
  } = props;

  const { t } = useTranslation();

  return (
    <div
      ref={ref}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={clsx(
        "bg-white m-4 p-4 rounded-lg flex flex-col gap-2 cursor-pointer hover:shadow",
        is_new ? "outline outline-1 outline-[#5566FF]" : "",
      )} // 'transition' caused animation flicker
      onClick={() => {
        onOrderClick(id);

        if (is_new) {
          queryClient.setQueryData(
            ["orders", status],
            (oldOrders?: ListResponse<Order[]>) => {
              if (typeof oldOrders !== "undefined") {
                const clone = structuredClone(oldOrders);
                const currOrder = clone.results.find(
                  (order) => order.id === id,
                );

                if (typeof currOrder !== "undefined") {
                  currOrder.is_new = false;
                }
                return clone;
              }

              return oldOrders;
            },
          );
        }
      }}
      aria-hidden
    >
      <div className="flex justify-between">
        <h4 className="text-base font-medium flex items-center gap-2">
          {t("order")} №{id}
          {is_new ? (
            <div className="w-1 h-1 bg-[#5566ff] rounded-full" />
          ) : null}
        </h4>
        <span className="text-gray-400">
          {lightFormat(new Date(created_at), "dd.MM.yyyy HH:mm")}
        </span>
      </div>

      <div className="flex justify-between">
        <Tag className="bg-white py-1 px-2 flex items-center gap-1">
          <OrderTypeIcon type={type} />
          {t(type)}
        </Tag>

        <div className="flex gap-2">
          {type === "shipping" ? (
            <Tag
              bordered={false}
              className={clsx(
                "py-1 px-2 mr-0",
                delivery_status === "delivered"
                  ? "bg-[#5566FF1A] text-[#5566FF]"
                  : "bg-[#F2994A1A] text-[#F2994A]",
              )}
            >
              {t(delivery_status ?? "took")}
            </Tag>
          ) : null}

          <Tag
            bordered={false}
            className={clsx(
              "py-1 px-2 mr-0",
              payment_status === "paid"
                ? "bg-[#2BC1281A] text-[#2BC128]"
                : "bg-[#FF1F001A] text-[#FF1F00]",
            )}
          >
            {t(payment_status)}
          </Tag>
        </div>
      </div>
    </div>
  );
}

const ItemCard = memo(forwardRef<HTMLDivElement, ItemProps>(Item));

Kanban.defaultProps = {
  className: "",
  extraBtn: undefined,
};

export default KanbanCard;
