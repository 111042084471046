import request from "@/utils/axios";
import type { NewProduct, UpdateProduct, UploadedFile } from "../types";
import { BaseParams, Category, ListResponse } from "@/types";
import { Order } from "@/features/all-orders";

export async function uploadFile(data: FormData): Promise<UploadedFile> {
  const res: UploadedFile = await request({
    url: "/menu/files/",
    method: "post",
    data,
  });
  return res;
}

export async function createProduct(data: NewProduct): Promise<void> {
  await request({
    url: "/menu/products/",
    method: "post",
    data,
  });
}

export async function updateProduct(
  id: number,
  data: UpdateProduct,
): Promise<void> {
  await request({
    url: `/menu/products/${id}/`,
    method: "put",
    data,
  });
}

export async function deleteProduct(id: number): Promise<void> {
  await request({
    url: `/menu/products/${id}/`,
    method: "delete",
  });
}


export async function updateCategory(
  id: number,
  data: UpdateProduct,
): Promise<void> {
  await request({
    url: `/menu/category/${id}/`,
    method: "put",
    data,
  });
}


export async function getOneCategory(id: number): Promise<Order> {
  const res: Order = await request({
    url: `/menu/category/${id}`,
    method: "get",
  });

  return res;
}