import { useEffect, useMemo, useState } from "react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Modal,
  Select,
  Table,
} from "antd";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { colors } from "@/config/theme";
import dayjs from "dayjs";
import queryClient from "@/utils/query-client";
import useStyledMessage from "@/hooks/use-styled-message";
import {
  createExpense,
  deleteExpense,
  getExpenses,
  updateExpense,
} from "../api";
import formatAmount from "@/helpers/format-amount";
import AddIcon from "@/components/add-icon";
import EditIcon from "@/components/edit-icon";
import TrashIcon from "@/components/trash-icon";
import SuccessIcon from "@/components/success-icon";
import MoneyIcon from "@/components/money-icon";

import type { IExpense } from "@/features/all-orders";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { ExpenseValues } from "../types";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import PreferencesIcon from "@/components/preferences-icon";
import DownloadIcon from "@/components/download-icon";
import { downloadExcel } from "@/features/report/api";
import { uid } from "radash";
import ChartIcon from "@/components/chart-icon";

const { Header, Content } = Layout;
const { TextArea } = Input;

interface Filters {
  min_date: string | null;
  max_date: string | null;
  branch: number;
}

export default function Expenses(): React.ReactElement {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const form = Form.useForm()[0];
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const { contextHolder, open } = useStyledMessage();

  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<Filters>({
    min_date: null,
    max_date: null,
    branch: 1,
  });
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalId, setModalId] = useState<number>();
  const pageSize = 10;

  const branchTypes = [
    { label: "IT-Park", value: 1 },
    { label: "Fillial-2", value: 2 },
  ];

  const createMutation = useMutation({
    mutationFn: async (payload: ExpenseValues) => {
      if (typeof modalId === "undefined") {
        await createExpense(payload);
      } else {
        await updateExpense(modalId, payload);
      }
    },
    onSuccess: () => {
      setIsModalOpen(false);
      form.resetFields();
      void queryClient.invalidateQueries(["expenses"]);
      setModalId(undefined);
      open({
        type: "success",
        content:
          typeof modalId === "undefined"
            ? t("expense-created")
            : t("expense-updated"),
        icon: <SuccessIcon style={{ color: colors.primary }} />,
      });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: deleteExpense,
    onSuccess: () => {
      setIsDelModalOpen(false);
      form.resetFields();
      void queryClient.invalidateQueries(["expenses"]);
      setModalId(undefined);
      open({
        type: "success",
        content: t("expense-deleted"),
        icon: <SuccessIcon style={{ color: colors.primary }} />,
      });
    },
  });

  const fetchAllExpenses = async (page: number) => {
    const res = await getExpenses({
      page: page,
      page_size: 10,
      min_date: filters.min_date,
      max_date: filters.max_date,
      branch: filters.branch,
    });

    return {
      data: res.results, // Har bir sahifadagi natijalar
      total: res.count, // Umumiy ma'lumotlar soni (paginatsiya uchun)
    };
  };

  // useQuery hook: sahifa raqamiga asoslangan ma'lumotlarni olib keladi
  const {
    data: expensesData,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["expenses", page], // Sahifa raqamiga bog'lab ishlatish
    queryFn: () => fetchAllExpenses(page),
    keepPreviousData: true, // Oldingi sahifadagi ma'lumotlarni saqlash (paginatsiya uchun qulay)
  });

  const showDelModal = (id?: number): void => {
    if (typeof id === "number") {
      setModalId(id);
    }
    setIsDelModalOpen(true);
  };

  const handleDelete = (id: number): void => {
    deleteMutation.mutate(id);
  };

  const handleDelCancel = (): void => {
    setIsDelModalOpen(false);
  };

  const showModal = (record?: IExpense): void => {
    if (record?.id) {
      setModalId(record.id);
    }
    setIsModalOpen(true); // Open modal first

    setTimeout(() => {
      form.setFieldsValue({
        created_at: record?.created_at ? moment(record.created_at) : "", // Ensure valid date format
        comment: record?.comment || "",
        amount: record?.amount || 0,
      });
    }, 100); // Small delay to ensure modal is open
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
    setModalId(undefined);
    form.resetFields();
  };

  const submitHandler = (values: ExpenseValues): void => {
    console.log(values);

    createMutation.mutate(values);
  };

  const paginationProps = {
    current: page,
    pageSize: pageSize,
    total: expensesData?.total || 0, // Umumiy ma'lumotlar soni
    onChange: (pageNumber: number) => {
      setPage(pageNumber); // Sahifa raqami o'zgarishi bilan so'rov yuboriladi
    },
  };

  const onPageChange: TableProps<IExpense>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
  };

  const columns = useMemo(() => {
    const allColumns: ColumnsType<IExpense> = [
      {
        key: "created_at",
        dataIndex: "created_at",
        render(value) {
          return dayjs(value).locale(currentLang).format("DD MMMM, YYYY");
        },
      },
      {
        key: "amount",
        dataIndex: "amount",
        render(value) {
          return `${formatAmount(value)} UZS`;
        },
      },
      {
        key: "comment",
        dataIndex: "comment",
        render(value) {
          return <div className="flex items-center gap-4">{value}</div>;
        },
      },
      {
        key: "actions", // Give this column a meaningful key
        dataIndex: "id", // Keep this to access the id easily
        render: (id: number, record: IExpense) => (
          <div className="flex gap-4">
            <Button
              icon={<EditIcon />}
              className="bg-[#eef0ff] text-[#5566ff] border-none"
              onClick={() => {
                console.log(record);

                showModal(record);
              }} // Uses the individual ID properly
            />

            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#FF1F00",
                },
              }}
            >
              <Button
                icon={<TrashIcon />}
                className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                onClick={() => {
                  console.log("ID", id);
                  showDelModal(id);
                }} // Uses the individual ID here too
              />
            </ConfigProvider>
          </div>
        ),
        width: "120px", // Adjust width to make the buttons visible
      },
    ];

    return allColumns;
  }, [currentLang]);

  useEffect(() => {
    if (typeof modalId !== "undefined") {
      const foundExpense =
        expensesData?.results?.find((expense) => expense.id === modalId) ||
        null;

      form.setFieldsValue({
        created_at: foundExpense?.created_at
          ? dayjs(foundExpense.created_at)
          : undefined,
        comment: foundExpense?.comment || undefined,
        amount: foundExpense?.amount || undefined,
      });
    }
  }, [modalId, expensesData, form]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const download = (): void => {
    void downloadExcel().then((res) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(
        new Blob([res], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      link.download = `file_${uid(8, "-")}.xlsx`;
      link.click();
    });
  };
  return (
    <>
      <Header
        className={`${
          isTabletOrMobile ? "grid" : "hidden"
        } bg-white grid grid-cols-3 items-center fixed bottom-0 z-[1] justify-between border-y-[1px] px-4`}
      >
        <Button
          className="border-none flex flex-col gap-1 shadow-none items-center justify-center"
          onClick={() => {
            navigate("/reports");
            // setOpen(true);
            // setIsLoggingOut(false);
          }}
        >
          <PreferencesIcon style={{ color: colors.primary }} />
          <span className="text-[10px] text-[#35314D]">{t("filter")}</span>
        </Button>
        <Button
          className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
          onClick={() => {
            download(); // Call the download function
            navigate("/reports"); // Navigate to /reports after download
          }}
        >
          <DownloadIcon style={{ color: colors.primary }} />
          <span className="text-[10px] text-[#35314D]">
            {t("download-excel")}
          </span>
        </Button>
        <Button
          className="border-none flex flex-col shadow-none gap-1 items-center justify-center"
          onClick={() => {
            navigate("/reports");
            // hide();
          }}
        >
          <ChartIcon style={{ color: colors.primary }} />{" "}
          <span className="text-[10px] text-[#35314D]">{t("reports")}</span>
        </Button>
      </Header>
      {contextHolder}
      <Header className="bg-white flex items-center justify-between border-y-[1px] px-6">
        <h1 className="sm:text-2xl text-xl font-semibold">{t("expenses")}</h1>

        <div className="flex items-center gap-4">
          <div className="sm:flex items-center gap-4 hidden ">
            <Select
              className="w-40"
              placeholder={t("Выберите филиал")}
              options={branchTypes}
              value={filters.branch}
              onChange={(id: number) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  branch: id,
                  min_date: null,
                  max_date: null,
                }));

                refetch();
              }}
            />
            <DatePicker
              // format="DD.MM.YYYY"
              placeholder={t("from") ?? ""}
              value={filters.min_date ? dayjs(filters.min_date) : null}
              onChange={(_, stringDate) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  min_date: stringDate,
                }))
              }
              inputReadOnly
            />

            <DatePicker
              // format="DD.MM.YYYY"
              placeholder={t("to") ?? ""}
              value={filters.max_date ? dayjs(filters.max_date) : null}
              onChange={(_, stringDate) =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  max_date: stringDate,
                }))
              }
              inputReadOnly
            />
          </div>
          <Button
            type="primary"
            className="flex items-center"
            size="large"
            onClick={() => {
              showModal();
            }}
          >
            <AddIcon />
            {t("add-expense")}
          </Button>
        </div>
      </Header>

      <Content className="bg-[#f5f5f5] sm:px-[50px] ps-6 py-6 mb-8">
        <ConfigProvider
          renderEmpty={() => (
            <div
              className="flex items-center justify-center"
              style={{
                minHeight:
                  "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px - 64px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <MoneyIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-expense-added")}
                </span>
              </div>
            </div>
          )}
        >
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={expensesData?.data}
            onChange={onPageChange}
            pagination={paginationProps}
            showHeader={false}
            loading={
              createMutation.isLoading || deleteMutation.isLoading || isFetching
            }
            className="custom-menu-table"
            scroll={{ x: "max-content" }}
          />
        </ConfigProvider>
      </Content>

      <Modal
        title={null}
        closeIcon={null}
        open={isDelModalOpen}
        footer={null}
        centered
        onCancel={handleDelCancel}
      >
        <div className="absolute top-0 w-full left-0">
          <h1 className="font-semibold text-2xl leading-7 px-9 pt-6 flex items-center justify-between">
            {t("delete-expense")}

            <CloseOutlined
              style={{ fontSize: "16px" }}
              onClick={handleDelCancel}
            />
          </h1>
          <Divider className="my-4" />
        </div>

        <div className="flex flex-col items-center justify-center gap-4 mb-8 mt-[93px]">
          <TrashIcon style={{ color: "#FF1F00" }} />
          <span className="w-80 text-center">
            {t("confirm-delete-expense")}
          </span>

          <div className="flex gap-4">
            <Button
              className="bg-[#f5f5f5] border-none"
              type="text"
              onClick={handleDelCancel}
            >
              {t("cancel")}
            </Button>
            <ConfigProvider theme={{ token: { colorPrimary: "#FF1F00" } }}>
              <Button
                className="bg-[#ffe9e6] text-[#FF1F00] border-none"
                onClick={() => {
                  // typeof modalId !== "undefined" && handleDelete(modalId);
                  handleDelete(modalId);
                }}
              >
                {t("delete")}
              </Button>
            </ConfigProvider>
          </div>
        </div>
      </Modal>

      <Modal
        title={null}
        closeIcon={null}
        open={isModalOpen}
        footer={null}
        centered
        onCancel={handleCancel}
        width={639}
      >
        <div className="absolute top-0 w-full left-0">
          <h1 className="font-semibold text-2xl leading-7 px-9 pt-6 flex items-center justify-between">
            {typeof modalId !== "undefined"
              ? t("edit-expense")
              : t("add-expense")}

            <CloseOutlined
              style={{ fontSize: "16px" }}
              onClick={handleCancel}
            />
          </h1>
          <Divider className="my-4" />
        </div>
        <div className="gap-4 mb-7 mt-[69px] px-4">
          <Form
            name="menu-control-form"
            layout="vertical"
            onFinish={submitHandler}
            autoComplete="off"
            form={form}
            requiredMark={false}
          >
            <div className="grid grid-cols-2 gap-4">
              <Form.Item
                label={t("select_period")}
                name="created_at"
                rules={[
                  {
                    required: true,
                    // type: "string",
                    // min: 0,
                    // whitespace: true,
                    // message: t("field-min-0") ?? "",
                  },
                ]}
              >
                {/* <InputNumber className="w-full" /> */}
                <DatePicker
                  format="DD.MM.YYYY"
                  placeholder=""
                  className="w-full"
                  type="number"
                />
              </Form.Item>

              <Form.Item
                label={t("amount_expenses")}
                name="amount"
                rules={[
                  {
                    required: true,
                    type: "number",
                    // min: 100,
                    // whitespace: true,
                    // message: t("field-min-100") ?? "",
                  },
                ]}
              >
                <InputNumber className="w-full" type="number" />
              </Form.Item>
            </div>
            <Form.Item
              label={t("comment")}
              name="comment"
              rules={[
                {
                  required: true,
                  type: "string",
                  // min: 100,
                  // whitespace: true,
                  // message: t("field-min-100") ?? "",
                },
              ]}
            >
              <TextArea className="w-full !resize-none !min-h-[110px]" />
            </Form.Item>

            <div className="flex justify-end items-center">
              <Button
                type="primary"
                htmlType="submit"
                className="flex items-center"
                size="large"
                loading={createMutation.isLoading}
              >
                {typeof modalId !== "undefined"
                  ? t("edit-expense")
                  : t("add-expense")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}
