import { useTranslation } from "react-i18next";
import clsx from "clsx";
import formatAmount from "@/helpers/format-amount";
import useStyledMessage from "@/hooks/use-styled-message";
import DishImage from "@/components/dish-image";
import Counter from "./counter";
import type { Order } from "../types";

interface Props {
  order: Order;
  onOrderItemInc: (opts: { id: number; qty: number; product: number }) => void;
  onOrderItemDec: (opts: { id: number; qty: number; product: number }) => void;
  onDeleteItem: (opts: { id: number }) => void;
}

export default function OrderItemList(props: Props): React.ReactElement {
  const { order, onOrderItemInc, onOrderItemDec, onDeleteItem } = props;

  const { t } = useTranslation();

  const { contextHolder, open } = useStyledMessage();

  const height = (() => {
    if (order?.payment_status === "not_paid") {
      if (order?.type === "self") {
        return "calc(100vh - 101px - 16px - 16px - 359px - 32px)";
      }
      return "calc(100vh - 101px - 16px - 16px - 470px - 32px)";
    }
    if (order?.payment_status === "paid") {
      if (order?.type === "self") {
        return "calc(100vh - 101px - 16px - 16px - 396px)";
      }
      return "calc(100vh - 101px - 16px - 16px - 507px)";
    }
    return "";
  })();

  return (
    <>
      {contextHolder}
      <div
        className="flex flex-col gap-4 overflow-y-hidden hover:overflow-y-auto"
        style={{
          height,
        }}
      >
        {order?.items?.map(({ price, qty, product, id }) => (
          <div key={id} className="flex gap-2">
            <DishImage src={product.image} />

            <div className="flex-1 flex flex-col justify-around">
              <div className="flex justify-between">
                <span className="text-sm leading-4 font-medium w-72 whitespace-nowrap text-ellipsis overflow-hidden">
                  {product.translations.ru.name}
                </span>
                <span className="text-sm leading-4 font-semibold whitespace-nowrap">
                  {formatAmount(price ?? 0)} UZS
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="textm-sm leading-4 text-[#7D848B]">
                  <Counter
                    count={qty}
                    onIncrement={() => {
                      if (order.payment_status === "not_paid") {
                        const maxCount = product.qty;
                        const inCartCount =
                          order.items.find((item) => item.id === id)?.qty ?? 0;

                        if (inCartCount < maxCount) {
                          onOrderItemInc({
                            id,
                            qty: qty + 1,
                            product: product.id,
                          });
                        } else {
                          open({
                            type: "error",
                            content: t("dish-count-reached-max"),
                          });
                        }
                      }
                    }}
                    onDecrement={() => {
                      if (order.payment_status === "not_paid") {
                        onOrderItemDec({
                          id,
                          qty: qty === 0 ? 0 : qty - 1,
                          product: product.id,
                        });
                      }
                    }}
                  />
                </span>

                <button
                  type="button"
                  className={clsx(
                    "bg-transparent text-[#FF1F00] hover:text-red-400 transition active:text-red-500",
                    order?.payment_status === "paid"
                      ? "cursor-not-allowed"
                      : "",
                  )}
                  onClick={() => {
                    if (order.payment_status === "not_paid") {
                      onDeleteItem({ id });
                    }
                  }}
                  disabled={order?.payment_status === "paid"}
                >
                  {t("delete")}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
