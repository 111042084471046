import { useMemo, useState } from "react";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Layout,
  Select,
  Table,
  Tag,
} from "antd";
import type { PaginationProps } from "antd";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { getOrders, getPaymentTypes } from "@/features/all-orders";
import { colors } from "@/config/theme";
import dayjs from "dayjs";
import Cheque from "@/components/cheque";
import InvoiceIcon from "@/components/invoice-icon";
import DishIcon from "@/components/dish-icon";
import OrderTypeIcon from "@/components/order-type-icon";
import NoteIcon from "@/components/note-icon";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { Dayjs } from "dayjs";
import type { Order, OrderItem } from "@/features/all-orders";
import type { OrderStatus, OrderType } from "@/types";

import "dayjs/locale/en";
import formatAmount from "@/helpers/format-amount";
import useBranchConfig from "@/hooks/use-branch";

const { Header, Content } = Layout;

const { RangePicker } = DatePicker;

interface Filters {
  self: boolean;
  shipping: boolean;
  date: [string, string] | null;
  payment_type: number | undefined;
}

export default function OrdersHistory(): React.ReactElement {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState<OrderStatus>("close");
  const [currOrder, setCurrOrder] = useState<Order>();
  const [isChequeOpen, setIsChequeOpen] = useState(false);
  const { branch } = useBranchConfig();

  const [filters, setFilters] = useState<Filters>({
    self: true,
    shipping: true,
    date: null,
    payment_type: undefined,
  });

  const { data: paymentTypesData } = useQuery({
    queryKey: ["payment-types"],
    queryFn: async () => {
      const res = await getPaymentTypes();
      return res;
    },
  });

  const paymentTypes = paymentTypesData?.results
    ?.filter((pType) => pType.status)
    ?.map(({ id, translations }) => ({
      label:
        currentLang === "en"
          ? translations?.ru?.name === "Наличными"
            ? "Cash"
            : translations?.ru?.name
          : translations?.ru?.name,
      value: id,
    }));

  const statusTypes = [
    { label: t("archived"), value: "archive" },
    { label: t("paid"), value: "close" },
  ];

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      "orders",
      { ...filters, date: getDateVal(filters.date) },
      page,
      pageSize,
      status,
      branch,
    ],
    queryFn: async () => {
      const type = getOrderType(filters);

      let minDate, maxDate;

      if (filters.date !== null) {
        const [minDateStr, maxDateStr] = filters.date;

        if (minDateStr !== "" && maxDateStr !== "") {
          minDate = minDateStr;
          maxDate = maxDateStr;
        }
      }

      const res = await getOrders({
        page,
        page_size: pageSize,
        ordering: "-id",
        branch: branch !== null ? branch : undefined,
        status,
        type,
        min_date: minDate,
        max_date: maxDate,
        payment_type: filters.payment_type,
      });

      return res;
    },
  });

  const paginationProps: PaginationProps = {
    defaultCurrent: page,
    defaultPageSize: pageSize,
    total: data?.count ?? 0,
    locale: { items_per_page: "" },
  };

  const onPageChange: TableProps<Order>["onChange"] = (
    pagination,
    _filters,
    _sorter,
    _extra,
  ) => {
    setPage(pagination.current ?? 1);
    setPageSize(pagination.pageSize ?? 10);
  };

  const columns = useMemo(() => {
    const allColumns: ColumnsType<Order> = [
      {
        key: "order-id",
        dataIndex: "id",
        render(value: string) {
          return (
            <span className="text-base font-semibold">
              {t("order")} №{value}
            </span>
          );
        },
      },
      {
        key: "created_at",
        dataIndex: "created_at",
        render(value) {
          return dayjs(value)
            .locale(currentLang)
            .format("DD MMMM, YYYY | HH:mm");
        },
      },
      {
        key: "items",
        dataIndex: "items",
        render(value: OrderItem[]) {
          return (
            <Tag className="bg-white mr-0 py-1 px-2 flex items-center gap-1 w-28">
              <DishIcon style={{ color: colors.primary }} /> {value.length}{" "}
              {t("positions")}
            </Tag>
          );
        },
      },
      {
        key: "type",
        dataIndex: "type",
        render(value) {
          return (
            <Tag className="bg-white mr-0 py-1 px-2 flex items-center gap-1 w-28">
              <OrderTypeIcon type={value} />
              {t(value)}
            </Tag>
          );
        },
      },
      // {
      //   key: "payment_type",
      //   dataIndex: "payment_type",
      //   render(value) {
      //     return value?.translations.ru.name;
      //   },
      // },
      {
        key: "payment_type",
        dataIndex: "payment_type",
        render(value) {
          return currentLang === "en"
            ? value?.translations?.ru?.name === "Наличными"
              ? "Cash"
              : value?.translations?.ru?.name
            : value?.translations?.ru?.name;
        },
      },
      {
        key: "total",
        dataIndex: "total",
        render(value) {
          return `${formatAmount(value)} UZS`;
        },
      },
      {
        key: "payment-status",
        dataIndex: "payment_status",
        render(value: boolean, record) {
          const isArchived = record.status === "archive";
          return (
            <Tag
              bordered={false}
              color={value ? (isArchived ? "default" : "success") : "error"}
              className="mr-0 py-2"
            >
              {value ? (isArchived ? t("archived") : t("paid")) : t("not_paid")}
            </Tag>
          );
        },
      },
      {
        key: "see-check",
        render(_value, record) {
          if (record.status === "archive") return;

          return (
            <Button
              className="flex-1 flex items-center justify-center text-[#5566ff] border-[#5566ff]"
              onClick={() => {
                setCurrOrder(record);
                setIsChequeOpen(true);
              }}
            >
              <InvoiceIcon />
              {t("see-check")}
            </Button>
          );
        },
        width: "0",
      },
    ];

    return allColumns;
  }, [t]);

  return (
    <>
      <Header className="bg-white flex items-center justify-between border-y-[1px]">
        <h1 className="text-2xl font-semibold">
          {t("orders-history")} ({data?.count})
        </h1>

        <div className="flex items-center gap-2">
          <Select
            className="w-40"
            placeholder={t("order-type")}
            allowClear
            options={[
              { label: t("self"), value: "self" },
              { label: t("shipping"), value: "shipping" },
            ]}
            onChange={(val: "self" | "shipping" | undefined) => {
              const { ...filtersCopy } = filters;

              if (typeof val === "undefined") {
                filtersCopy.self = true;
                filtersCopy.shipping = true;
              }

              if (val === "self") {
                filtersCopy.self = true;
                filtersCopy.shipping = false;
              }

              if (val === "shipping") {
                filtersCopy.self = false;
                filtersCopy.shipping = true;
              }

              setFilters(filtersCopy);
            }}
          />

          <Select
            className="w-40"
            placeholder={t("payment-method")}
            allowClear
            options={paymentTypes}
            onChange={(id: number) => {
              setFilters({ ...filters, payment_type: id });
            }}
          />

          <Select
            className="w-40"
            placeholder={t("status")}
            options={statusTypes}
            onChange={(id: string) => {
              setStatus(id);
            }}
            value={status}
          />

          <RangePicker
            placeholder={[t("from"), t("to")]}
            value={getDateVal(filters.date)}
            onChange={(_dayjsRange, formattedVals) => {
              setFilters({ ...filters, date: formattedVals });
            }}
          />
        </div>
      </Header>

      <Content className="bg-[#f5f5f5] px-[50px] py-6">
        <ConfigProvider
          renderEmpty={() => (
            <div
              className="flex items-center justify-center"
              style={{
                minHeight:
                  "calc(100vh - 64px - 64px - 24px - 24px - 16px - 16px - 1px)",
              }}
            >
              <div className="flex flex-col items-center gap-2">
                <NoteIcon style={{ color: colors.primary }} />
                <span className="text-base leading-6 font-normal text-[#2F3138]">
                  {t("no-finished-orders")}
                </span>
              </div>
            </div>
          )}
        >
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data?.results}
            onChange={onPageChange}
            pagination={paginationProps}
            showHeader={false}
            loading={isLoading || isFetching}
            className="custom-history-table"
          />
        </ConfigProvider>
      </Content>

      <Cheque
        order={currOrder}
        isOpen={isChequeOpen}
        setIsOpen={setIsChequeOpen}
      />
    </>
  );
}

function getOrderType(filters: {
  self: boolean;
  shipping: boolean;
}): OrderType | undefined {
  const { self, shipping } = filters;

  if (self && shipping) return undefined;

  if (self) return "self";

  if (shipping) return "shipping";

  return undefined;
}

function getDateVal(input: null | [string, string]): [Dayjs, Dayjs] | null {
  if (input === null) return null;

  if (Array.isArray(input)) {
    const [minDate, maxDate] = input;

    if (minDate === "" || maxDate === "") {
      return null;
    }

    return input.map(dayjs) as [Dayjs, Dayjs];
  }

  return null;
}
