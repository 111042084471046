import { Order, OrdersParams } from "@/features/all-orders/types";
import { ListResponse } from "@/types";
import request from "@/utils/axios";

export async function downloadExcel(): Promise<BinaryData> {
  const res: BinaryData = await request({
    url: "/order/download_excel/",
    method: "get",
    responseType: "arraybuffer",
  });

  return res;
}

export async function getOrderRaiseStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_order_raise_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getOrderTypeStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_order_type_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getOrderedProductsStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_ordered_products_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getPaymentTypeStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_payment_type_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getProductCategoryStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/order/get_product_category_statistics/",
    method: "get",
    params,
  });

  return res;
}

export async function getOrderExpenseStats(
  params?: OrdersParams,
): Promise<ListResponse<Order[]>> {
  const res: ListResponse<Order[]> = await request({
    url: "/expenses/get_expense_statistics/",
    method: "get",
    params,
  });

  return res;
}

export const a = null;
